import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {SpinnerInfoBox} from 'rio-uikit';

import {
  csvExportSystemState,
  fetchDeviceStateNotifications,
  onRangeChange,
  onSelectMessageTypeFilter
} from '../actions/systemStateActions';
import {
  getCurrentPage,
  getDeviceStateNotifications,
  getEndDateTime,
  getLastPage,
  getLoadingOfDeviceInfo,
  getSelectedMessageTypeFilter,
  getStartDateTime,
} from '../selectors/systemStateSelectors';

import DeviceFinder from './DeviceFinder';
import SystemStateFilter from './SystemStateFilter';
import DevicesSystemStateTable from './SystemStateOverviewTable';
import LoadMoreComponent from './LoadMoreComponent';

class SystemStateOverviewPage extends Component {

  render() {
    const {intl, isLastPage} = this.props;

    return (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <h1><FormattedMessage id='intl-msg:devicestates'/></h1>
              <br/>
              <h2><FormattedMessage
                  id='intl-msg:devicesSendSystemStateNotifications'/></h2>
              <br/>
              <div className='row'>
                <div className='col-md-2'>
                  <DeviceFinder
                      onShowControlDevice={(serialNumber) => this.onShowDevice(
                          serialNumber)}/>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <SystemStateFilter
                      selectedMessageTypeFilter={this.props.selectedMessageTypeFilter}
                      onSelectMessageTypeFilter={this.props.onSelectMessageTypeFilter}
                      onTimeRangeChange={this.props.onRangeChange}
                      startValue={this.props.startDateTime}
                      endValue={this.props.endDateTime}
                      csvExportSystemState={this.csvExportSystemState}
                      fetchSystemStateNotifications={this.fetchFirstDeviceStateNotifications}
                      searchButtonText={<FormattedMessage
                          id='intl-msg:search'/>}
                  />
                </div>
              </div>
              {
                this.props.loading &&
                <div>
                  <SpinnerInfoBox text={'Loading'} isInverse={false}/>
                </div>
              }
              <div>
                <DevicesSystemStateTable
                    content={this.props.deviceStateNotifications}
                    onSelectionChange={this.onRowSelect}/>
              </div>
              <LoadMoreComponent isLastPage={isLastPage}
                                 loadMoreData={this.loadMoreData}/>
            </div>
          </div>
        </div>
    );
  }

  componentDidMount = () => {
    if (this.props.deviceStateNotifications.length === 0) {
      this.fetchFirstDeviceStateNotifications();
    }
  }

  fetchFirstDeviceStateNotifications = () => {
    this.fetchDeviceStateNotifications(0);
  }

  loadMoreData = () => {
    this.fetchDeviceStateNotifications(this.props.currentPage + 1);
  }

  fetchDeviceStateNotifications = (page) => {
    const startDateTime = this.props.startDateTime.valueOf();
    const endDateTime = this.props.endDateTime.valueOf();
    const selectedMessageFilter = this.props.selectedMessageTypeFilter;
    this.props.fetchDeviceStateNotifications(selectedMessageFilter,
        startDateTime, endDateTime, page);
  }

  csvExportSystemState = () => {
    const startDateTime = this.props.startDateTime.valueOf();
    const endDateTime = this.props.endDateTime.valueOf();
    this.props.csvExportSystemState(this.props.selectedMessageTypeFilter,
        startDateTime, endDateTime);
  }

  onShowDevice = ({serialNumber}) => {
    const {history} = this.props;
    const route = `/systemstate/${serialNumber}`;
    history.push(route)
  }

  onRowSelect = (selectedElementsNew) => {
    if (selectedElementsNew) {
      const {deviceStateNotifications} = this.props;
      const selectedRow = deviceStateNotifications.filter(
          row => row.id === selectedElementsNew[0]);
      this.onShowDevice(selectedRow[0]);
    }
  }
}

export const mapStateToProps = (state) => ({
  loading: getLoadingOfDeviceInfo(state),
  deviceStateNotifications: getDeviceStateNotifications(state),
  selectedMessageTypeFilter: getSelectedMessageTypeFilter(state),
  startDateTime: getStartDateTime(state),
  endDateTime: getEndDateTime(state),
  isLastPage: getLastPage(state),
  currentPage: getCurrentPage(state),
});

export const mapDispatchToProps = (dispatch) => ({
  onSelectMessageTypeFilter: (newSelectedMessageFilter) => {
    dispatch(onSelectMessageTypeFilter(newSelectedMessageFilter));
  },
  fetchDeviceStateNotifications: (selectedMessageFilter, startTimestamp,
      endTimestamp, nextPage) => {
    dispatch(
        fetchDeviceStateNotifications(selectedMessageFilter, startTimestamp,
            endTimestamp, nextPage));
  },
  csvExportSystemState: (selectedMessageTypeFilter,
      startTimestamp, endTimestamp) => {
    dispatch(csvExportSystemState('', selectedMessageTypeFilter,
        startTimestamp, endTimestamp));
  },
  onRangeChange: (startValue, endValue) => {
    dispatch(onRangeChange(startValue, endValue));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(SystemStateOverviewPage));

