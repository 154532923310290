const getDevicesSigning = state => state.deviceMonitor.devicesSigning;

export const getLoadingState = state => getDevicesSigning(state).loading;
export const getSigningDevices = state => getDevicesSigning(state).signingDevices;
export const getSigningState = state => getDevicesSigning(state).signingState;
export const getShowDevicetype = state => getDevicesSigning(state).showDevicetype;
export const getStartDateTime = state => getDevicesSigning(state).startDateTime;
export const getEndDateTime = state => getDevicesSigning(state).endDateTime;
export const getAmountOfDevices = state => getDevicesSigning(state).amountOfDevices;
export const getCurrentPage = state => getDevicesSigning(state).currentPage;
export const getLastPage = state => getDevicesSigning(state).isLastPage;
