const getSingleDevice = state => state.deviceMonitor.singleDevice;

export const getDeviceOfSingleDevice = state => getSingleDevice(state).device;
export const getLoadingOfSingleDevices = state => getSingleDevice(state).loading;

export const getCurrentView = state => getSingleDevice(state).currentView;

export const tbm3CertificatesSelector = state => getSingleDevice(state).tbm3Certificates;
export const vcmCertificatesSelector = state => getSingleDevice(state).vcmCertificates;
export const getSelectedCertificateSelector = state => getSingleDevice(state).certificate;
export const getDevicePropertiesSelector = state => getSingleDevice(state).device;
