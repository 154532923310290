import PropTypes from 'prop-types';

import classNames from 'classnames';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SortArrows, SortDirection } from 'rio-uikit';

const renderSortArrow = (columnDescriptor, sortBy = 'something', sortDir = SortDirection.ASCENDING) => {
    const { id, sortable } = columnDescriptor;
    if (sortable) {
        if (sortBy === id) {
            return <SortArrows direction={sortDir} />;
        }
        return <SortArrows />;
    }
};

const toLabel = (columnDescriptor) => {
    const { formatLabel, label, id } = columnDescriptor;
    if (formatLabel) {
        return formatLabel(id);
    }
    if (columnDescriptor.label) {
        return <FormattedMessage id={label}/>;
    }
    return <FormattedMessage id={`intl-msg:field.unknown.${id}`}/>;
};

export const TableHeader = ({ columnDescriptor, sortBy, sortDir }) => {
    if (columnDescriptor.hidden) {
        return null;
    }
    const { id, className } = columnDescriptor;
    const label = toLabel(columnDescriptor);
    const sorting = renderSortArrow(columnDescriptor, sortBy, sortDir);
    const headerClassNames = classNames('user-select-none', { 'sort-column': !!sorting }, className);
    return (
        <th key={id}
            className={headerClassNames}
            data-field={id}
            data-sortby={id}>
            <span>
                {sorting}
                {label}
            </span>
        </th>
    );
};

export default TableHeader;

TableHeader.propTypes = {
    // props
    columnDescriptor: PropTypes.object,
    sortBy: PropTypes.string,
    sortDir: PropTypes.string,
};
