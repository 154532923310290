// This is the runtime configuration being used for tests

export const testConfig = {
    id: 'env.test',
    backend: {
        DEVICE_MONITORING_SERVICE: `${window.location.origin}/api/deviceinitmonitor`,
        SYSTEM_STATE_NOTIFICATIONS_SERVICE: `${window.location.origin}/api/tbm3systemstatenotification/1.0.0`,
        DEVICE_SERVICE: `${window.location.origin}/api/deviceService/v1`,
        MESSAGESENDER_SERVICE: `${window.location.origin}/api/tbm3systemstatenotification`,
    },
    homeRoute: `${window.location.origin}`,
    appMenuUri: `${window.location.origin}/#app-menu`,
    login: {
        authority: `${window.location.origin}/oauth`,
        mockAuthorization: false,
        preventRedirect: false,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
    logoutUri: `${window.location.origin}`,
};

