import PropTypes from 'prop-types';

import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { getLanguageData, getLocale } from '~/features/lang/selectors';
import { DEFAULT_LOCALE } from '~/features/lang/lang';

export const IntlContainer = ({ children, userLocale, languageData }) => {
    return (
        <IntlProvider locale={userLocale} messages={languageData} defaultLocale={DEFAULT_LOCALE}>
            {children}
        </IntlProvider>
    );
};

export const mapStateToProps = (state) => ({
    languageData: getLanguageData(state),
    userLocale: getLocale(state),
});

export default connect(mapStateToProps)(IntlContainer);

IntlContainer.defaultProps = {
};

IntlContainer.propTypes = {
    userLocale: PropTypes.string,
    languageData: PropTypes.any,
};
