import PropTypes from 'prop-types';

import React from 'react';

// TODO Think about killing this if there's nothing more to it
export const SelectionTableHeader = ({ allowSelection }) => {
    if (!allowSelection) {
        return null;
    }
    return (
        <th>
        </th>
    );
};

export default SelectionTableHeader;

SelectionTableHeader.defaultProps = {
    // props
    allowSelection: false,
};

SelectionTableHeader.propTypes = {
    // props
    allowSelection: PropTypes.bool,
};
