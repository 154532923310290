export const FETCH_DEVICE_CERTIFICATES = 'FETCH_DEVICE_CERTIFICATES';
export const SUCCESSFUL_FETCH_DEVICE_CERTIFICATES = 'SUCCESSFUL_FETCH_DEVICE_CERTIFICATES';
export const FAILED_FETCH_DEVICE_CERTIFICATES = 'FAILED_FETCH_DEVICE_CERTIFICATES';
export const FETCH_DEVICE_PROPERTIES = 'FETCH_DEVICE_PROPERTIES';
export const SUCCESSFUL_FETCH_DEVICE_PROPERTIES = 'SUCCESSFUL_FETCH_DEVICE_PROPERTIES';
export const FAILED_FETCH_DEVICE_PROPERTIES = 'FAILED_FETCH_DEVICE_PROPERTIES';
export const FETCH_CERTIFICATE = 'FETCH_CERTIFICATE';
export const SUCCESSFUL_FETCH_CERTIFICATE = 'SUCCESSFUL_FETCH_CERTIFICATE';
export const FAILED_FETCH_CERTIFICATE = 'FAILED_FETCH_CERTIFICATE';

export const fetchCertificatesForDevice = (serialNumber, vcmSerialNumber) => {
    return {
        type: FETCH_DEVICE_CERTIFICATES,
        serialNumber,
        vcmSerialNumber,
    };
};
export const successfulFetchCertificatesForDevice = (tbm3Payload, vcmPayload) => {
    return {
        type: SUCCESSFUL_FETCH_DEVICE_CERTIFICATES,
        tbm3Payload,
        vcmPayload,
    };
};
export const failedFetchCertificatesForDevice = (error) => {
    return {
        type: FAILED_FETCH_DEVICE_CERTIFICATES,
        error,
    };
};

export const fetchPropertiesForDevice = (serialNumber) => {
    return {
        type: FETCH_DEVICE_PROPERTIES,
        serialNumber,
    };
};
export const successfulFetchPropertiesForDevice = (payload) => {
    return {
        type: SUCCESSFUL_FETCH_DEVICE_PROPERTIES,
        payload,
    };
};
export const failedFetchPropertiesForDevice = (error) => {
    return {
        type: FAILED_FETCH_DEVICE_PROPERTIES,
        error,
    };
};

export const fetchCertificate = (certSerialNumber) => {
    return {
        type: FETCH_CERTIFICATE,
        certSerialNumber,
    };
};
export const successfulFetchCertificate = (payload) => {
    return {
        type: SUCCESSFUL_FETCH_CERTIFICATE,
        payload,
    };
};
export const failedFetchCertificate = (error) => {
    return {
        type: FAILED_FETCH_CERTIFICATE,
        error,
    };
};
