/* eslint-disable camelcase */
export class DeviceInfo {
    constructor(arrInfo) {
        this.Vin7 = arrInfo[0];
        this.Vin17 = arrInfo[1];
        this.Imei = arrInfo[2];
        this.Iccid = arrInfo[3];
        this.created = arrInfo[4];
    }
}
export class SREDeviceInfo {
    constructor(arrInfo) {
        this.ID = arrInfo[0];	
        this.TBM3_IMEI = arrInfo[1];
        this.TBM3_man_part_number = arrInfo[2];
        this.TBM3_serial_number = arrInfo[3];
        this.TBM3_software_version = arrInfo[4];
        this.TBM3_hardware_version = arrInfo[5];
        this.TBM3_supplier_part_number = arrInfo[6];
        this.TBM3_device_type = arrInfo[7];
        this.VCM_imei = arrInfo[8];
        this.VCM_serial_number = arrInfo[9];
        this.VCM_man_part_number = arrInfo[10];
        this.VCM_software_version = arrInfo[11];
        this.VCM_hardware_version = arrInfo[12];
        this.VCM_supplier_part_number = arrInfo[13];
        this.VCM_device_type = arrInfo[14];
        this.zsb_man_part_number = arrInfo[15];
        this.zsb_supplier_part_number = arrInfo[16];
        this.zsb_date_assembly = arrInfo[17];
        this.SIM_ID = arrInfo[18];
        this.IMSI = arrInfo[19];
        this.ICCID = arrInfo[20];
        this.PRIMARY_MSISDN = arrInfo[21];
        this.SP = arrInfo[22];
        this.STATE = arrInfo[23];
        this.CUSTOMER_CODE = arrInfo[24];
        this.TARIFF_NAME = arrInfo[25];
        this.DATE_CREATED = arrInfo[26];
        this.DATE_ACTIVE_TEST_STARTED = arrInfo[27];
        this.network_test_result = arrInfo[28];
        this.network_test_date = arrInfo[29];

        this.join = this.join.bind(this);
    }

    join(sep) {
        return this.ID.concat(sep,
            this.TBM3_IMEI, sep,
            this.TBM3_man_part_number, sep,
            this.TBM3_serial_number, sep,
            this.TBM3_software_version, sep,
            this.TBM3_hardware_version, sep,
            this.TBM3_supplier_part_number, sep,
            this.TBM3_device_type, sep,
            this.VCM_imei, sep,
            this.VCM_serial_number, sep,
            this.VCM_man_part_number, sep,
            this.VCM_software_version, sep,
            this.VCM_hardware_version, sep,
            this.VCM_supplier_part_number, sep,
            this.VCM_device_type, sep,
            this.zsb_man_part_number, sep,
            this.zsb_supplier_part_number, sep,
            this.zsb_date_assembly, sep,
            this.SIM_ID, sep,
            this.IMSI, sep,
            this.ICCID, sep,
            this.PRIMARY_MSISDN, sep,
            this.SP, sep,
            this.STATE, sep,
            this.CUSTOMER_CODE, sep,
            this.TARIFF_NAME, sep,
            this.DATE_CREATED, sep,
            this.DATE_ACTIVE_TEST_STARTED, sep,
            this.network_test_result, sep,
            this.network_test_date);
    }
}

export default DeviceInfo;
