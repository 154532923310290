import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, Link } from 'react-router-dom';

import { getDeviceOfSingleDevice, getLoadingOfSingleDevices, getCurrentView } from './../selectors/singleDeviceSelectors';
import DevicePropertiesContainer from './DevicePropertiesContainer';
import DeviceCertificatesContainer from './DeviceCertificatesContainer';
import { DeviceView } from './../constants/deviceConstants';

import classNames from 'classnames';

class CheckSingleDevicesPage extends Component {

    render() {

        const { serialNumberOfDevice, history } = this.props;
        const content = this.renderContent();

        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className='btn btn-link' onClick={() => history.goBack()}>
                            <span className='rioglyph rioglyph-chevron-left' aria-hidden='true'></span>
                            <FormattedMessage id='intl-msg:backToDevices' />
                        </button>
                        <h1><FormattedMessage id='intl-msg:infoForDevice' /> {serialNumberOfDevice}</h1>
                        <br />
                        {content}
                    </div>
                </div>
            </div>
        );
    }

    renderContent = () => {
        const tabs = this.renderTabs();
        const routes = this.renderRoutes();

        return [
            <div className='col-md-12'>
                {tabs}
            </div>,
            routes,
        ];
    }

    renderRoutes = () => {
        const { serialNumberOfDevice, match } = this.props;
        if (!serialNumberOfDevice || !match) {
            return;
        }
        return (
            <Switch>
                <Route path={`${match.path}/${DeviceView.PROP}`} render={() =>
                    <DevicePropertiesContainer {...this.props} serialNumber={serialNumberOfDevice} />
                } />
                <Route path={`${match.path}/${DeviceView.CERT}`} render={() =>
                    <DeviceCertificatesContainer {...this.props} serialNumber={serialNumberOfDevice} />
                } />
                <Redirect to={`${match.url}/${DeviceView.PROP}`} />
            </Switch>
        );
    }

    renderTabs = () => {
        const { currentView, match } = this.props;
        return (
            <ul className='nav nav-tabs user-select-none' role='tablist'>
                <li className={classNames({ active: currentView === DeviceView.PROP })}>
                    <Link to={`${match.url}/${DeviceView.PROP}`}>
                        <FormattedMessage id='intl-msg:properties' />
                    </Link>
                </li>
                <li className={classNames({ active: currentView === DeviceView.CERT })}>
                    <Link to={`${match.url}/${DeviceView.CERT}`}>
                        <FormattedMessage id='intl-msg:certificates' />
                    </Link>
                </li>
            </ul>
        );
    }

}

export const mapStateToProps = (state) => ({
    device: getDeviceOfSingleDevice(state),
    loading: getLoadingOfSingleDevices(state),
    currentView: getCurrentView(state),
});

export const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CheckSingleDevicesPage));
