import { call, put, select, fork, takeLatest } from 'redux-saga/effects';

import {
    FETCH_SIGNING_DEVICES, successfulFetchSiningDevices, failedFetchSiningDevices,
} from './../actions/devicesSigningActions';

import { showErrorMessage } from './../actions/ui/notificationsActions';
import { getDeviceBackend } from './../selectors/backendSelectors';

import { doHandleErrorSaga, getHTTP } from './sagaUtil';

export function* getServiceURL() {
    const serviceURL = yield select(getDeviceBackend);
    return `${serviceURL}`;
}

export function* doFetchSiningDevices(action) {
    try {
        const url = yield call(getServiceURL);
        const method = action.signingState;
        const params = `devicetype=${action.devicetype}&starttimespan=${action.startTimestamp}&endtimespan=${action.endTimestamp}&page=${action.nextPage}&size=50`;
        const response = yield call(getHTTP, `${url}/devices/devicedatamodel/${method}?${params}`);
        yield put(successfulFetchSiningDevices(response, action));
    } catch (error) {
        yield fork(doHandleErrorSaga, error);
        yield put(failedFetchSiningDevices(error));
        yield put(showErrorMessage(error));
    }
}


export function* fetchSiningDevicesSaga() {
    yield takeLatest(FETCH_SIGNING_DEVICES, doFetchSiningDevices);
}
