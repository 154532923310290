// This is the runtime configuration being used for development
// against a local instance of an `authorization-server`. Note
// that the redirect only works if you run the `webpack-dev-server`
// on http://localhost:8081.

export const localAuthConfig = {
    id: 'env.localauth',
    backend: {
        DEVICE_MONITORING_SERVICE: 'http://localhost:8080/api/deviceinitmonitor',
        SYSTEM_STATE_NOTIFICATIONS_SERVICE: 'http://localhost:8080/api/tbm3systemstatenotification/v1',
        DEVICE_SERVICE: 'http://localhost:8080/api/deviceService',
        MESSAGESENDER_SERVICE: 'http://localhost:8080/api/tbm3systemstatenotification/v1',
    },
    homeRoute: `${window.location.origin}`,
    appMenuUri: 'http://localhost:8040/#app-menu',
    userMenuUri: 'http://localhost:8040/#user-menu',
    login: {
        authority: 'http://localhost:8443',
        clientId: 'test-client',
        mockAuthorization: true,
        preventRedirect: false,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
    logoutUri: `http://localhost:8443/logout?redirect_uri=${window.location.origin}`,
    features: {
        nasysAvailable: true,
        vcmAvailable: true,
        certificatesAvailable: true,
    },
};
