export const FETCH_SIGNING_DEVICES = 'FETCH_SIGNING_DEVICES';
export const SUCCESSFUL_FETCH_SIGNING_DEVICES = 'SUCCESSFUL_FETCH_SIGNING_DEVICES';
export const FAILED_FETCH_SIGNING_DEVICES = 'FAILED_FETCH_SIGNING_DEVICES';
export const RANGE_CHANGE_SIGNING_DEVICES = 'RANGE_CHANGE_SIGNING_DEVICES';

export const SigningState = { SUCCESSFUL: 'successful', REJECTED: 'rejected', RECURRENT: 'recurrent' };

export const fetchSiningDevices = (startTimestamp, endTimestamp, signingState, devicetype, nextPage) => {
    return {
        type: FETCH_SIGNING_DEVICES,
        startTimestamp,
        endTimestamp,
        signingState,
        devicetype,
        nextPage,
    };
};
export const successfulFetchSiningDevices = (payload, filterAction) => {
    return {
        type: SUCCESSFUL_FETCH_SIGNING_DEVICES,
        payload,
        filterAction,
    };
};
export const failedFetchSiningDevices = (error) => {
    return {
        type: FAILED_FETCH_SIGNING_DEVICES,
        error,
    };
};

export const onRangeChange = (startValue, endValue) => {
    return {
        type: RANGE_CHANGE_SIGNING_DEVICES,
        startValue, endValue,
    };
};
