import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './ValueWithHint.less';

const FONTSIZES = ['small', 'medium', 'large'];
const COLORS = ['mutes', 'primary', 'success', 'warning', 'info', 'danger'];

const ValueWithHint = (props) => {
    const { value, valueColor, valueSize, hint, hintColor, hintSize, unit, unitSize, className } = props;

    const wrapperClass = classnames(className, 'text-bold');

    const unitSizeClass = classnames('unit',
        (FONTSIZES.indexOf(unitSize) !== -1) && `size-${unitSize}`);

    const valueClasses = classnames('value',
        (COLORS.indexOf(valueColor) !== -1) && `text-${valueColor}`,
        (FONTSIZES.indexOf(valueSize) !== -1) && `size-${valueSize}`);

    const hintClasses = classnames('hint',
        (COLORS.indexOf(hintColor) !== -1) && `text-${hintColor}`,
        (FONTSIZES.indexOf(hintSize) !== -1) && `size-${hintSize}`);

    return (
        <div className={classnames('ValueWithHint', wrapperClass)}>
            <div className={valueClasses}>
                {value && <span className={'value'}>{value} </span>}
                {unit && <span className={unitSizeClass}>{unit}</span>}
            </div>
            {hint && <div className={hintClasses}>{hint}</div>}
        </div>
    );
};

ValueWithHint.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    valueColor: PropTypes.string,
    valueSize: PropTypes.string,
    hint: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    hintColor: PropTypes.string,
    hintSize: PropTypes.string,
    unit: PropTypes.string,
    unitSize: PropTypes.string,
    className: PropTypes.string,
};

ValueWithHint.defaultProps = {
    value: 'ValueWithHint',
    valueColor: 'default',
    valueSize: 'large',
    hint: '',
    hintColor: 'default',
    hintSize: 'small',
    unit: '',
    unitSize: 'medium',
    className: '',
};

export default ValueWithHint;