import { searchDevicesSaga, fetchFilteredDevicesSaga, exportFilteredDevicesSaga } from './showResultSaga';
import { checkDevicesAndAddResultsToDbSaga, checkSREDevicesAndAddResultsToDbSaga } from './uploadSaga';
import {
    exportFilteredDevicesSystemStateSaga,
    fetchDeviceStateNotificationsSaga,
    fetchStateNotificationsOfDeviceSaga,
    requestStateNotificationFromDevice,
} from './systemStateSaga';
import { fetchSiningDevicesSaga } from './devicesSigningSaga';

import {
    fetchDevicePropertiesSaga, fetchDevicesCertificatesSaga,
    fetchCertificateSaga,
} from './singleDeviceSaga';

// ui
import { showErrorMessagesSaga } from './ui/notificationsSaga';
import { fetchNasysJournalRecordsSaga } from './journal/nasysJournalSaga';
import { fetchStoneridgeJournalRecordsSaga } from './journal/stoneridgeJournalSaga';
import { fetchServiceCertificateRecordsSaga} from "./certificateSaga";

const sagas = [
    searchDevicesSaga,
    fetchFilteredDevicesSaga,
    exportFilteredDevicesSaga,
    checkDevicesAndAddResultsToDbSaga,
    checkSREDevicesAndAddResultsToDbSaga,
    fetchDeviceStateNotificationsSaga,
    fetchSiningDevicesSaga,
    fetchDevicePropertiesSaga,
    fetchDevicesCertificatesSaga,
    fetchCertificateSaga,
    fetchStateNotificationsOfDeviceSaga,
    requestStateNotificationFromDevice,
    exportFilteredDevicesSystemStateSaga,
    fetchNasysJournalRecordsSaga,
    fetchStoneridgeJournalRecordsSaga,
    fetchServiceCertificateRecordsSaga,
    // ui
    showErrorMessagesSaga,
];

export default sagas;
