export const CSV_EXPORT_DEVICES = 'CSV_EXPORT_DEVICES';
export const SUCCESSFUL_CSV_EXPORT_DEVICES = 'SUCCESSFUL_CSV_EXPORT_DEVICES';
export const SEARCH_DEVICES = 'SEARCH_DEVICES';
export const FAILED_SEARCH_DEVICES = 'FAILED_SEARCH_DEVICES';

export const FETCH_FILTERED_DEVICES = 'FETCH_FILTERED_DEVICES';
export const SUCCESSFUL_FETCH_FILTERED_DEVICES = 'SUCCESSFUL_FETCH_FILTERED_DEVICES';
export const FAILED_FETCH_FILTERED_DEVICES = 'FAILED_FETCH_FILTERED_DEVICES';

export const csvExport = (dataState) => {
    return {
        type: CSV_EXPORT_DEVICES,
        dataState,
    };
};

export const successfulExportFilteredDevices = (exportDevicesData, filename) => {
    return {
        type: SUCCESSFUL_CSV_EXPORT_DEVICES,
        exportDevicesData,
        filename,
    };
};

export const searchDevices = (searchCategory, searchPattern, deviceType, history) => {
    return {
        type: SEARCH_DEVICES,
        searchCategory,
        searchPattern,
        deviceType,
        history,
    };
};

export const failedSearchDevices = (error) => {
    return {
        type: FAILED_SEARCH_DEVICES,
        error,
    };
};

/*
'nosuccess' -> 'getAllNotInitDevices'
'success' -> 'getAllSuccessfulInitDevices'
'replaced' -> 'getAllReplacedDevices'
'unfiltered' -> 'gettbms'
*/
export const fetchFilteredDevices = (dataState, pageToShow) => {
    return {
        type: FETCH_FILTERED_DEVICES,
        dataState,
        pageToShow,
    };
};

export const successfulFetchFilteredDevices = (response) => {
    return {
        type: SUCCESSFUL_FETCH_FILTERED_DEVICES,
        payload: response,
    };
};

export const failedFetchFilteredDevices = (error) => {
    return {
        type: FAILED_FETCH_FILTERED_DEVICES,
        error,
    };
};
