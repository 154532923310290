import {
    FETCH_STONERIDGE_JOURNAL_RECORDS,
    SUCCESSFUL_STONERIDGE_FETCH_JOURNAL_RECORDS,
} from '../../actions/journal/stoneridgeJournalActions';

const DEFAULT_STATE = {
    loading: false,
    journalRecords: [],
    paging: {
        total: 0,
        page: 0,
        pages: 0,
    },
};

const setLoading = (state, loading) => {
    return {
        ...state,
        loading,
    };
};

function successfulFetchJournalRecords(state, action) {
    const { content, number, totalPages, totalElements, last, pageable } = action.payload;
    const oldRecords = number > 0 ? state.journalRecords : [];
    return {
        ...state,
        ...action.payload,
        loading: false,
        journalRecords: oldRecords.concat(content),
        paging: {
            total: totalElements,
            page: number,
            pages: totalPages,
        },
    };
}

export function stoneridgeJournalReducer(state = DEFAULT_STATE, action = {}) {
    switch (action.type) {
        case FETCH_STONERIDGE_JOURNAL_RECORDS:
            return setLoading(state, true);
        case SUCCESSFUL_STONERIDGE_FETCH_JOURNAL_RECORDS:
            return successfulFetchJournalRecords(state, action);
        default:
            return state;
    }
}

export default stoneridgeJournalReducer;
