import {
    SUCCESSFUL_FETCH_DEVICE_CERTIFICATES,
    FAILED_FETCH_DEVICE_CERTIFICATES,
    SUCCESSFUL_FETCH_CERTIFICATE,
    FAILED_FETCH_CERTIFICATE,
    SUCCESSFUL_FETCH_DEVICE_PROPERTIES,
    FAILED_FETCH_DEVICE_PROPERTIES,
} from '../actions/singleDeviceActions';

import { DeviceView } from '../constants/deviceConstants';

const DEFAULT_STATE = {
    device: undefined,
    loading: false,
    currentView: DeviceView.PROP,
    tbm3Certificates: [],
    vcmCertificates: [],
    certificate: '',
};

const successfulFetchDeviceCertificates = (state, action) => {
    return {
        ...state,
        loading: false,
        currentView: DeviceView.CERT,
        device: {},
        tbm3Certificates: action.tbm3Payload,
        vcmCertificates: action.vcmPayload,
    };
};

const successfulFetchCertificate = (state, action) => {
    return {
        ...state,
        loading: false,
        certificate: action.payload,
    };
};

const successfulFetchDeviceProperties = (state, action) => {
    return {
        ...state,
        loading: false,
        currentView: DeviceView.PROP,
        device: action.payload,
    };
};

const setLoading = (state, loading) => {
    return {
        ...state,
        loading,
    };
};

export function singleDeviceReducer(state = DEFAULT_STATE, action = {}) {
    switch (action.type) {
        case SUCCESSFUL_FETCH_DEVICE_CERTIFICATES:
            return successfulFetchDeviceCertificates(state, action);
        case SUCCESSFUL_FETCH_CERTIFICATE:
            return successfulFetchCertificate(state, action);
        case SUCCESSFUL_FETCH_DEVICE_PROPERTIES:
            return successfulFetchDeviceProperties(state, action);
        case FAILED_FETCH_DEVICE_CERTIFICATES:
            return setLoading(state, false);
        case FAILED_FETCH_CERTIFICATE:
            return setLoading(state, false);
        case FAILED_FETCH_DEVICE_PROPERTIES:
            return setLoading(state, false);
        default:
            return state;
    }
}

export default singleDeviceReducer;
