import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import { RioBootstrapTable, RioTableHeaderColumn } from 'rio-uikit-legacy-table';

class SystemStateOverviewTable extends Component {

    render() {
        const { intl, content, onSelectionChange } = this.props;
        const options = {
            noDataText: intl.formatMessage({ id: 'intl-msg:noDataText' })
        };
        
        if (content) {
            for (let i = 0; i < content.length; i++) {
                content[i].time = intl.formatDate(new Date(content[i].timestamp), {
                    year: 'numeric', month: 'numeric', day: 'numeric',
                    hour: 'numeric', minute: 'numeric', second: 'numeric',
                });
                if (content[i].restartedProcesses.length > 0) {
                    let restartedProcesses_list = [];
                    content[i].restartedProcesses.forEach(p => restartedProcesses_list.push(p.name))
                    content[i].restartedProcesses_list = restartedProcesses_list.join(', ');
                }
                for (let j=0; j < content[i].processLogLevel.length; j++) {
                    let sep = j > 0 ? ', ' : '';
                    let processLogLevel =  content[i].processLogLevel[j];
                    content[i].processLogLevels = sep + processLogLevel.name + " (" + processLogLevel.logLevel + ")";
                }
            }
        }
        return (
            <RioBootstrapTable data={ content } keyField='id'
                pagination={ false }
                options={ options }
                enableRowSelection={ true }
                singleSelect={ true }
                clickToSelect={ true }
                onSelectionChange={ onSelectionChange }
                searchPlaceholder={ intl.formatMessage({ id: 'intl-msg:searchTable' }) }
                search>
              <RioTableHeaderColumn dataField='serialNumber' searchable headerText={intl.formatMessage({ id: 'intl-msg:serialNumberHeader' })} width='150'>{intl.formatMessage({ id: 'intl-msg:serialNumberHeader' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='time' headerText={intl.formatMessage({ id: 'intl-msg:notificationTime' })} width='150'>{intl.formatMessage({ id: 'intl-msg:notificationTime' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='average_cpu' headerText={intl.formatMessage({ id: 'intl-msg:avgCpuTooltip' })} width='100'>{'AVG CPU (%)'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='cpu_usage' headerText='CPU (%)' width='80'>{'CPU (%)'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='loadavg_15' headerText='LOAD AVG 15' width='100'>{'LOAD AVG 15'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='mem_usage' headerText='MEM USAGE (kB)' width='120'>{'MEM USAGE (kB)'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='mem_free' headerText='FREE MEM (kB)' width='120'>{'FREE MEM (kB)'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='base_sw_version' columnTitle headerText={intl.formatMessage({ id: 'intl-msg:base_sw_version' })} width='110'>{intl.formatMessage({ id: 'intl-msg:base_sw_version' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='restartedProcesses_list' searchable columnTitle headerText={intl.formatMessage({ id: 'intl-msg:restartedProcesses' })} width='100'>{intl.formatMessage({ id: 'intl-msg:restarted' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='processesNotRunning' searchable columnTitle headerText={intl.formatMessage({ id: 'intl-msg:notRunningProcesses' })} width='150'>{intl.formatMessage({ id: 'intl-msg:notRunning' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='processLogLevels' columnTitle headerText={intl.formatMessage({ id: 'intl-msg:processLogLevels' })} width='100'>{intl.formatMessage({ id: 'intl-msg:processLogLevels' })}</RioTableHeaderColumn>
            </RioBootstrapTable>
        );
    }
    
}

export default injectIntl(SystemStateOverviewTable);
