import { FETCH_JOURNAL_RECORDS, SUCCESSFUL_FETCH_JOURNAL_RECORDS } from '../../actions/journal/nasysJournalActions';

const DEFAULT_STATE = {
    loading: false,
    journalRecords: [],
    paging: {
        total: 0,
        page: 0,
        pages: 0,
    },
};

const setLoading = (state, loading) => {
    return {
        ...state,
        loading,
    };
};

function successfulFetchJournalRecords(state, action) {
    const journal = action.payload.content;
    return {
        ...state,
        ...action.payload,
        loading: false,
        journalRecords: state.journalRecords.concat(journal),
        paging: {
            total: action.payload.totalElements,
            page: action.payload.number,
            pages: action.payload.totalPages,
        },
    };
}

export function nasysJournalReducer(state = DEFAULT_STATE, action = {}) {
    switch (action.type) {
        case FETCH_JOURNAL_RECORDS:
            return setLoading(state, true);
        case SUCCESSFUL_FETCH_JOURNAL_RECORDS:
            return successfulFetchJournalRecords(state, action);
        default:
            return state;
    }
}

export default nasysJournalReducer;
