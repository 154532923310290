import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SingleButtonDropdown, SpinnerInfoBox } from 'rio-uikit';
import ValueWithHint from './ValueWithHint';
import LoadMoreComponent from './LoadMoreComponent.js';
import ExtDeviceFinder from './ExtDeviceFinder';
import { DeviceType } from '../constants/deviceConstants';

import { searchDevices, fetchFilteredDevices, csvExport } from '../actions/showResultActions';

import {
    getDevicesOfShowResult,
    getSuccessDevicesOfShowResult,
    getNoSuccessDevicesOfShowResult,
    getLostDevicesOfShowResult,
    getReplacedDevicesOfShowResult,
    getCurrentPageOfShowResult,
    getTotalPagesOfShowResult,
    getTotalDevicesOfShowResult,
    getLoadingOfShowResult,
} from '../selectors/showResultSelectors';
import ListTable from './table/ListTable';
import { SHOW_RESULTS_TABLE_COLUMN_DESCRIPTORS } from './table/ColumnDescriptors';

class ShowResultPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dataWhichIsShown: '',
            recievedSavedDevices: '',
            filterTitle: 'Show all data',
            searchTitle: 'Category to search for',
        };

        this.showSuccessfulInitatedDevices = this.showSuccessfulInitatedDevices.bind(this);
        this.showNotSuccessfulInitatedDevices = this.showNotSuccessfulInitatedDevices.bind(this);
        this.showReplacedDevices = this.showReplacedDevices.bind(this);
        this.showLostDevices = this.showLostDevices.bind(this);
        this.getUnfilteredData = this.getUnfilteredData.bind(this);
        this.getFilteredData = this.getFilteredData.bind(this);
        this.decideWhichDataToShow = this.decideWhichDataToShow.bind(this);
        this.csvExport = this.csvExport.bind(this);
        this.showAllDevices = this.showAllDevices.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
    }

    render() {
        const totalAmountOfDevices = this.props.successfulInitiatedDevices + this.props.notSuccessfulInitiatedDevices;
        const percentForSuccess = totalAmountOfDevices === 0 ?
            '-' : (this.props.successfulInitiatedDevices / totalAmountOfDevices * 100).toFixed(2);
        const percentForNoSuccess = totalAmountOfDevices === 0 ?
            '-' : (this.props.notSuccessfulInitiatedDevices / totalAmountOfDevices * 100).toFixed(2);
        const percentForLost = totalAmountOfDevices === 0 ?
            '-' : (this.props.lostDevices / totalAmountOfDevices * 100).toFixed(2);
        const percentForReplaced = totalAmountOfDevices === 0 ?
            '-' : (this.props.replacedDevices / totalAmountOfDevices * 100).toFixed(2);
        const { history } = this.props;

        const dataSetWithAllTbms = this.props.devices;
        if (dataSetWithAllTbms && dataSetWithAllTbms.length > 0) {
            dataSetWithAllTbms.forEach((device, index) => {
                dataSetWithAllTbms[index].initSuccessfulText =
                    device.initSuccessful ? 'Init Successful' : 'Not certified';
                dataSetWithAllTbms[index].vehicleInitText = device.vehicleInit ? 'true' : 'false';
                dataSetWithAllTbms[index].replacedText = device.replaced ? 'Replaced!!!' : 'Not replaced';
            });
        }
        return (
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h1>{'NASYS Result'}</h1>
                        <br />
                        <h2>{'Detailed Results'}</h2>
                        <div className='panel panel-default'>
                            <div className='panel-body'>
                                <ul className='list-inline list-unstyled'>
                                    <li className='col-sm-3'>
                                        <ValueWithHint value={this.props.successfulInitiatedDevices}
                                            valueColor='primary' valueSize='large'
                                            hint='Successful signed Devices from NASYS' hintFormat='muted'
                                            hintSize='small' unit={` (${percentForSuccess}%)`} unitSize='medium' />
                                    </li>
                                    <li className='col-sm-3'>
                                        <ValueWithHint value={this.props.notSuccessfulInitiatedDevices}
                                            valueColor='warning' valueSize='large' hint='Not signed Devices'
                                            hintFormat='muted' hintSize='small' unit={` (${percentForNoSuccess}%)`}
                                            unitSize='medium' />
                                    </li>
                                    <li className='col-sm-3'>
                                        <ValueWithHint value={this.props.replacedDevices}
                                            valueColor='warning' valueSize='large' hint='Replaced Devices'
                                            hintFormat='muted' hintSize='small' unit={` (${percentForReplaced}%)`}
                                            unitSize='medium' />
                                    </li>
                                    <li className='col-sm-3'>
                                        <ValueWithHint value={this.props.lostDevices} valueColor='danger'
                                            valueSize='large' hint='Lost Devices (Not signed since more than 90 days)'
                                            hintFormat='muted' hintSize='small' unit={` (${percentForLost}%)`}
                                            unitSize='medium' />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div className='col-md-6'>
                                <ExtDeviceFinder history={history} searchDevices={this.props.searchDevices} />
                            </div>
                        </div>
                        <br />
                        <div className='btn-toolbar'>
                            <SingleButtonDropdown bsStyle='primary' title={this.state.filterTitle}
                                items={[{
                                    value: 'Successful signed devices',
                                    disabled: this.props.loading,
                                    onSelect: this.showSuccessfulInitatedDevices,
                                }, {
                                    value: 'Not signed devices',
                                    disabled: this.props.loading,
                                    onSelect: this.showNotSuccessfulInitatedDevices,
                                }, {
                                    divider: true,
                                }, {
                                    value: 'Replaced devices',
                                    disabled: this.props.loading,
                                    onSelect: this.showReplacedDevices,
                                }, {
                                    value: 'Lost devices',
                                    disabled: this.props.loading,
                                    onSelect: this.showLostDevices,
                                }, {
                                    divider: true,
                                }, {
                                    value: 'Show all data',
                                    disabled: this.props.loading,
                                    onSelect: this.showAllDevices,
                                }]} />
                            <button className='btn btn-primary' onClick={this.csvExport}
                                disabled={this.props.loading}>
                                {'Export'}
                            </button>
                        </div>
                        <br />
                        {
                            this.props.loading &&
              <div>
                  <SpinnerInfoBox text={'Loading'} isInverse={false} />
              </div>
                        }
                        <ListTable items={dataSetWithAllTbms}
                            itemKey={'imei'}
                            columnDescriptors={SHOW_RESULTS_TABLE_COLUMN_DESCRIPTORS}
                            onRowClick={this.onRowSelect}
                        />
                        <LoadMoreComponent isLastPage={this.props.currentPage === this.props.maxPages - 1}
                            loadMoreData={this.decideWhichDataToShow} />
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const regexIsNumber = new RegExp('^[0-9]+$');
        if (regexIsNumber.test(this.props.match.params.resultpage)) {
            this.getUnfilteredData(parseInt(this.props.match.params.resultpage, 10));
        } else {
            this.getUnfilteredData(0);
        }
    }

    onRowSelect(item) {
        if (item) {
            const { history } = this.props;
            this.props.searchDevices('imei', item.imei, DeviceType.TBM3, history);
        }
    }

    csvExport(event) {
        event.preventDefault();
        this.props.csvExport(this.state.recievedSavedDevices);
    }

    decideWhichDataToShow() {
        let localCurrentPage = this.props.currentPage;
        if (localCurrentPage >= this.props.maxPages) {
            localCurrentPage = 0;
        } else {
            localCurrentPage = localCurrentPage + 1;
        }
        this.props.history.replace(localCurrentPage.toString());
        if (this.state.recievedSavedDevices === '') {
            this.getUnfilteredData(localCurrentPage);
        } else {
            this.getFilteredData(localCurrentPage, this.state.recievedSavedDevices);
        }
    }

    getFilteredData(pageToShow, dataState) {
        this.setState({
            dataWhichIsShown: `Currently selected data set '${dataState}'`,
        });

        this.props.fetchFilteredDevices(dataState, pageToShow);
    }

    getUnfilteredData(pageToShow) {
        this.setState({
            dataWhichIsShown: `Currently selected data set 'all'`,
        });

        this.props.fetchFilteredDevices('unfiltered', pageToShow);
    }

    showSuccessfulInitatedDevices() {
        this.setState({
            recievedSavedDevices: 'success',
            filterTitle: 'Successful signed devices',
        });
        this.getFilteredData(0, 'success');
    }

    showNotSuccessfulInitatedDevices() {
        this.setState({
            recievedSavedDevices: 'nosuccess',
            filterTitle: 'Not signed devices',
        });
        this.getFilteredData(0, 'nosuccess');
    }

    showReplacedDevices() {
        this.setState({
            recievedSavedDevices: 'replaced',
            filterTitle: 'Replaced devices',
        });
        this.getFilteredData(0, 'replaced');
    }

    showLostDevices() {
        this.setState({
            recievedSavedDevices: 'lost',
            filterTitle: 'Lost devices',
        });
        this.getFilteredData(0, 'lost');
    }

    showAllDevices() {
        this.setState({
            recievedSavedDevices: '',
            filterTitle: 'Show all data',
        });
        this.getUnfilteredData(0);
    }
}

export const mapStateToProps = (state) => ({
    devices: getDevicesOfShowResult(state),
    successfulInitiatedDevices: getSuccessDevicesOfShowResult(state),
    notSuccessfulInitiatedDevices: getNoSuccessDevicesOfShowResult(state),
    lostDevices: getLostDevicesOfShowResult(state),
    replacedDevices: getReplacedDevicesOfShowResult(state),
    currentPage: getCurrentPageOfShowResult(state),
    maxPages: getTotalPagesOfShowResult(state),
    totalDevices: getTotalDevicesOfShowResult(state),
    loading: getLoadingOfShowResult(state),
});

export const mapDispatchToProps = (dispatch) => ({
    csvExport: (dataState) => {
        dispatch(csvExport(dataState));
    },
    searchDevices: (searchCategory, currentSearchPattern, deviceType, history) => {
        dispatch(searchDevices(searchCategory, currentSearchPattern, deviceType, history));
    },
    fetchFilteredDevices: (dataState, pageToShow) => {
        dispatch(fetchFilteredDevices(dataState, pageToShow));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowResultPage);
