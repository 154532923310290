import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ButtonGroup, Button } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';

import { SpinnerInfoBox, DateRangePicker } from 'rio-uikit';

import ExtDeviceFinder from './ExtDeviceFinder';
import { searchDevices } from '../actions/showResultActions';
import { fetchSiningDevices, onRangeChange, SigningState } from '../actions/devicesSigningActions';
import {
    getLoadingState, getSigningDevices, getStartDateTime, getEndDateTime,
    getCurrentPage, getLastPage, getShowDevicetype, getSigningState,
} from '../selectors/devicesSigningSelectors';

import LoadMoreComponent from './LoadMoreComponent';

import { env } from '~/env';
const { features } = env.runtimeConfig;

import { DeviceType } from '../constants/deviceConstants';
import ListTable from './table/ListTable';
import { DEVICES_SIGNING_TABLE_COLUMN_DESCRIPTORS } from './table/ColumnDescriptors';

class DevicesSigningPage extends Component {

    render() {
        const { history, intl, isLastPage } = this.props;

        const dataSetWithAllDevices = this.props.signingDevices;
        if (dataSetWithAllDevices && dataSetWithAllDevices.length > 0) {
            dataSetWithAllDevices.forEach(element => {
                element.lastrequest = intl.formatDate(new Date(element.signingState.timestamp), {
                    year: 'numeric', month: 'numeric', day: 'numeric',
                    hour: 'numeric', minute: 'numeric', second: 'numeric',
                });
            });
        }

        return (
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h1><FormattedMessage id='intl-msg:devicesigning' /></h1><br />
                        <h4><FormattedMessage id='intl-msg:devicesLookup' /></h4>
                        <div className='row'>
                            <div className='col-md-4'>
                                <ExtDeviceFinder history={history} searchDevices={this.props.searchDevices} />
                            </div>
                        </div>
                        <br />
                        { this.renderFilterTitle(this.props.signingState) }
                        <span className='input-group'>
                            <ButtonGroup>
                                <Button active={this.props.signingState === SigningState.REJECTED}
                                    onClick={() => this.setShowSigningStateAndUpdate(SigningState.REJECTED)} >
                                    <FormattedMessage id='intl-msg:rejected' />
                                </Button>
                                <Button active={this.props.signingState === SigningState.RECURRENT}
                                    onClick={() => this.setShowSigningStateAndUpdate(SigningState.RECURRENT)} >
                                    <FormattedMessage id='intl-msg:recurrent' />
                                </Button>
                                <Button active={this.props.signingState === SigningState.SUCCESSFUL}
                                    onClick={() => this.setShowSigningStateAndUpdate(SigningState.SUCCESSFUL)} >
                                    <FormattedMessage id='intl-msg:successful' />
                                </Button>
                            </ButtonGroup>
                            &nbsp;&nbsp;&nbsp;
                            { features.vcmAvailable && this.renderDeviceTypeButtons() }
                            &nbsp;&nbsp;&nbsp;
                            <DateRangePicker hasTimePicker locale={`${intl.locale}`} textDefault={intl.formatMessage({ id: 'intl-msg:selectTimeRange' })}
                                textToday={intl.formatMessage({ id: 'intl-msg:today' })}
                                textLastWeek={intl.formatMessage({ id: 'intl-msg:oneWeek' })}
                                textLastMonth={intl.formatMessage({ id: 'intl-msg:oneMonth' })}
                                textCustom={intl.formatMessage({ id: 'intl-msg:customRange' })}
                                textFrom={intl.formatMessage({ id: 'intl-msg:from' })}
                                textTo={intl.formatMessage({ id: 'intl-msg:to' })}
                                textApply={intl.formatMessage({ id: 'intl-msg:ok' })}
                                textCancel={intl.formatMessage({ id: 'intl-msg:cancel' })}
                                customPresets={[{
                                    text: intl.formatMessage({ id: 'intl-msg:today' }),
                                    startValue: moment().startOf('day'),
                                    endValue: moment().endOf('day'),
                                }, {
                                    text: intl.formatMessage({ id: 'intl-msg:oneWeek' }),
                                    startValue: moment().startOf('day').subtract(1, 'weeks'),
                                    endValue: moment().endOf('day'),
                                }, {
                                    text: intl.formatMessage({ id: 'intl-msg:oneMonth' }),
                                    startValue: moment().startOf('day').subtract(1, 'months'),
                                    endValue: moment().endOf('day'),
                                }]}
                                startValue={this.props.startDateTime} endValue={this.props.endDateTime}
                                onRangeChange={(startValue, endValue) => this.props.onRangeChange(startValue, endValue)} />
                            <span className='input-group-btn'>
                                <button className='btn btn-primary' onClick={this.fetchSiningDevices}><FormattedMessage id='intl-msg:search' /></button>
                            </span>
                        </span>
                        {
                            this.props.loading &&
                            <div>
                                <SpinnerInfoBox text={'Loading'} isInverse={false} />
                            </div>
                        }
                        <div style={{marginTop: '20px'}}>
                            <ListTable items={this.props.signingDevices}
                                       itemKey={'imei'}
                                       columnDescriptors={DEVICES_SIGNING_TABLE_COLUMN_DESCRIPTORS}
                                       onRowClick={this.onRowSelect}/>
                        </div>
                        <LoadMoreComponent isLastPage={isLastPage} loadMoreData={this.loadMoreData} />
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.fetchSiningDevices();
    }

    onRowSelect = (item) => {
        if (item) {
            const { history } = this.props;
            const searchCategory = item.deviceType === DeviceType.VCM ? 'vcmserialnumber' : 'serialnumber';
            this.props.searchDevices(searchCategory, item.serialNumber, item.deviceType, history);
        }
    }

    setShowSigningStateAndUpdate = (signingState) => {
        const startDateTime = this.props.startDateTime.valueOf();
        const endDateTime = this.props.endDateTime.valueOf();
        this.props.fetchSiningDevices(startDateTime, endDateTime,
            signingState, this.props.showDevicetype, 0);
    }

    setShowDeviceTypeAndUpdate = (showDevicetype) => {
        const startDateTime = this.props.startDateTime.valueOf();
        const endDateTime = this.props.endDateTime.valueOf();
        this.props.fetchSiningDevices(startDateTime, endDateTime,
            this.props.signingState, showDevicetype, 0);
    }

    fetchSiningDevices = () => {
        const startDateTime = this.props.startDateTime.valueOf();
        const endDateTime = this.props.endDateTime.valueOf();
        this.props.fetchSiningDevices(startDateTime, endDateTime,
            this.props.signingState, this.props.showDevicetype, 0);
    }

    loadMoreData = () => {
        const startDateTime = this.props.startDateTime.valueOf();
        const endDateTime = this.props.endDateTime.valueOf();
        this.props.fetchSiningDevices(startDateTime, endDateTime,
            this.props.signingState, this.props.showDevicetype, this.props.currentPage + 1);
    }

    renderFilterTitle = (signingState) => {
        if (signingState === SigningState.REJECTED) {
            return <div>
                <h2><FormattedMessage id='intl-msg:devicesGotNoCertificate' /></h2><br />
            </div>;
        } else if (signingState === SigningState.SUCCESSFUL) {
            return <div>
                <h2><FormattedMessage id='intl-msg:devicesGotCertificate' /></h2><br />
            </div>;
        } 
        return <div>
            <h2><FormattedMessage id='intl-msg:devicesRecurrentCertificate' /></h2><br />
        </div>;
    }

    renderDeviceTypeButtons = () => {
        return <ButtonGroup>
            <Button active={this.props.showDevicetype === DeviceType.TBM3}
                onClick={() => this.setShowDeviceTypeAndUpdate(DeviceType.TBM3)} >
                {DeviceType.TBM3}
            </Button>
            <Button active={this.props.showDevicetype === DeviceType.VCM}
                onClick={() => this.setShowDeviceTypeAndUpdate(DeviceType.VCM)} >
                {DeviceType.VCM}
            </Button>
        </ButtonGroup>;
    }

}

export const mapStateToProps = (state) => ({
    loading: getLoadingState(state),
    signingDevices: getSigningDevices(state),
    startDateTime: getStartDateTime(state),
    showDevicetype: getShowDevicetype(state),
    signingState: getSigningState(state),
    endDateTime: getEndDateTime(state),
    isLastPage: getLastPage(state),
    currentPage: getCurrentPage(state),
});

export const mapDispatchToProps = (dispatch) => ({
    searchDevices: (searchCategory, currentSearchPattern, deviceType, history) => {
        dispatch(searchDevices(searchCategory, currentSearchPattern, deviceType, history));
    },
    fetchSiningDevices: (startTimestamp, endTimestamp, signingState, showDevicetype, nextPage) => {
        dispatch(fetchSiningDevices(startTimestamp, endTimestamp, signingState, showDevicetype, nextPage));
    },
    onRangeChange: (startValue, endValue) => {
        dispatch(onRangeChange(startValue, endValue));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DevicesSigningPage));
