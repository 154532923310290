import {call, fork, put, select, takeLatest} from 'redux-saga/effects';

import {
  CSV_EXPORT_DEVICES_SYSTEM_STATE,
  failedFetchDeviceStateNotifications,
  failedFetchStateNotificationsOfDevice,
  FETCH_DEVICE_STATE_NOTIFICATIONS,
  FETCH_STATE_NOTIFICATIONS_OF_DEVICE,
  REQUEST_STATE_NOTIFICATIONS_FROM_DEVICE,
  successfulFetchDeviceStateNotifications,
  successfulFetchStateNotificationsOfDevice,
} from '../actions/systemStateActions';

import {showErrorMessage} from '../actions/ui/notificationsActions';
import {
  getDeviceSystemStateBackend,
  getMessageSenderBackend
} from '../selectors/backendSelectors';

import {doHandleErrorSaga, getHTTP, getHTTPCSV, postHTTP} from './sagaUtil';
import moment from "moment";
import {successfulExportFilteredDevices} from "../actions/showResultActions";

export function* getServiceURL() {
  const serviceURL = yield select(getDeviceSystemStateBackend);
  return `${serviceURL}`;
}

export function* getMessageSenderServiceURL() {
  const serviceURL = yield select(getMessageSenderBackend);
  return `${serviceURL}`;
}

export function* doFetchDeviceStateNotifications(action) {
  try {
    const url = yield call(getServiceURL);
    const params = `starttimespan=${action.startTimestamp}&endtimespan=${action.endTimestamp}&filter=${action.messageFilter}&page=${action.nextPage}&size=50`;
    const response = yield call(getHTTP, `${url}/devices?${params}`);
    yield put(successfulFetchDeviceStateNotifications(response));
  } catch (error) {
    yield fork(doHandleErrorSaga, error);
    yield put(failedFetchDeviceStateNotifications(error));
    yield put(showErrorMessage(error));
  }
}

export function* doFetchStateNotificationsOfDevice(action) {
  try {
    const url = yield call(getServiceURL);
    const params = `starttimespan=${action.startTimestamp}&endtimespan=${action.endTimestamp}&filter=${action.messageFilter}`;
    const response = yield call(getHTTP,
        `${url}/devices/${action.serialNumber}/events?${params}`);
    yield put(successfulFetchStateNotificationsOfDevice(response));
  } catch (error) {
    yield fork(doHandleErrorSaga, error);
    yield put(failedFetchStateNotificationsOfDevice(error));
    yield put(showErrorMessage(error));
  }
}

export function* doRequestStateNotificationFromDevice(action) {
  try {
    const messageSenderUrl = yield call(getMessageSenderServiceURL);
    yield call(postHTTP,
        `${messageSenderUrl}/devices/${action.serialNumber}/systemstates`);
  } catch (error) {
    yield fork(doHandleErrorSaga, error);
    yield put(showErrorMessage(error));
  }
}

export function* doExportFilteredDevicesSystemState(action) {
  try {
    const {serialNumber, startTimestamp, endTimestamp, messageFilter} = action;
    let url = yield call(getServiceURL);
    let fileName = '';
    let response;
    if (serialNumber) {
      let params = `starttimespan=${startTimestamp}&endtimespan=${endTimestamp}&filter=${messageFilter}`;
      fileName = `Devices_${moment().format('YYYYMMDD-h:mm:ss')}.csv`;
      response = yield call(getHTTPCSV,
          `${url}/devices/${serialNumber}/events/export?${params}`);
    } else {
      // check timespan
      if ((action.endTimestamp - action.startTimestamp) > 2 * 24 * 3600 * 1000) {
        yield put(
            showErrorMessage(
                {message: "Timespan too big. Please reduce to a maximum of 2 days."}));
        return;
      }
      let params = `starttimespan=${startTimestamp}&endtimespan=${endTimestamp}&filter=${messageFilter}`;
      fileName = `DeviceNotifications_${moment().format(
          'YYYYMMDD-h:mm:ss')}.csv`;
      response = yield call(getHTTPCSV, `${url}/devices/export?${params}`);
    }
    yield put(successfulExportFilteredDevices(response, fileName));
  } catch (error) {
    yield fork(doHandleErrorSaga, error);
    yield put(showErrorMessage(error));
  }
}

export function* fetchDeviceStateNotificationsSaga() {
  yield takeLatest(FETCH_DEVICE_STATE_NOTIFICATIONS,
      doFetchDeviceStateNotifications);
}

export function* fetchStateNotificationsOfDeviceSaga() {
  yield takeLatest(FETCH_STATE_NOTIFICATIONS_OF_DEVICE,
      doFetchStateNotificationsOfDevice);
}

export function* requestStateNotificationFromDevice() {
  yield takeLatest(REQUEST_STATE_NOTIFICATIONS_FROM_DEVICE,
      doRequestStateNotificationFromDevice);
}

export function* exportFilteredDevicesSystemStateSaga() {
  yield takeLatest(CSV_EXPORT_DEVICES_SYSTEM_STATE,
      doExportFilteredDevicesSystemState);
}
