import { call, put, select, fork, takeLatest } from 'redux-saga/effects';

import {
    FETCH_DEVICE_PROPERTIES, successfulFetchPropertiesForDevice, failedFetchPropertiesForDevice,
    FETCH_DEVICE_CERTIFICATES, successfulFetchCertificatesForDevice, failedFetchCertificatesForDevice,
    FETCH_CERTIFICATE, successfulFetchCertificate, failedFetchCertificate,
} from './../actions/singleDeviceActions';

import { showErrorMessage } from './../actions/ui/notificationsActions';
import {
    getDeviceMonitoringBackend, getDeviceBackend,
    getDeviceSystemStateBackend,
} from './../selectors/backendSelectors';

import { doHandleErrorSaga, getHTTP, requestText } from './sagaUtil';

export function* getServiceURL() {
    const serviceURL = yield select(getDeviceMonitoringBackend);
    return `${serviceURL}`;
}

export function* getDeviceServiceURL() {
    const serviceURL = yield select(getDeviceBackend);
    return `${serviceURL}`;
}

export function* getDeviceSystemStateBackendURL() {
    const serviceURL = yield select(getDeviceSystemStateBackend);
    return `${serviceURL}`;
}

function transformDeviceData(response) {
    let device = { ...response[0] };
    if (response[0].sreData) {
        device = { ...device, iccid: response[0].sreData.iccid, imsi: response[0].sreData.imsi };
    } else if (response[0].certData) {
        device = { ...device, iccid: response[0].certData.iccid, imsi: response[0].certData.imsi };
    }
    if (response[0].signingState) {
        device = { ...device, ...response[0].signingState };
    }
    if (device.initSuccessful === undefined) {
        device.initSuccessful = (device.signingStatus === 'FETCHED_BY_DEVICE');
    }
    if (response.length === 2) {
        device.vcm = { ...response[1], ...response[1].signingState };
        if (device.vcm.initSuccessful === undefined) {
            device.vcm.initSuccessful = (device.vcm.signingStatus === 'FETCHED_BY_DEVICE');
        }
    }
    return device;
}

function* lookupDevice(deviceUrl, serialNumber) {
    const url = `${deviceUrl}/devices/${serialNumber}/ddm?identifier=serialnumber`;
    const response = yield call(getHTTP, url);
    const device = transformDeviceData(response);
    return device;
}

function* loadNasysData(nasysUrl, imei) {
    try {
        let response = yield call(getHTTP, `${nasysUrl}/devices/imei/${imei}`);
        if (Array.isArray(response)) {
            response = response[0];
        }
        return response;
    } catch (error) {
    // ignore errors in the additional calls
    // eslint-disable-next-line no-console
        console.log(error);
        return {};
    }
}

export function* doFetchDeviceProperties(action) {
    try {
        const deviceUrl = yield call(getDeviceServiceURL);
        const nasysUrl = yield call(getServiceURL);
        const systemStateUrl = yield call(getDeviceSystemStateBackendURL);

        let device = yield lookupDevice(deviceUrl, action.serialNumber);

        const imei = device.imei;
        // Can't use putHTTP because it tries to parse response as JSON
        yield call(requestText, `${nasysUrl}/devices/success-init/${imei}`, { method: 'PUT' });
        let response = yield loadNasysData(nasysUrl, imei);
        device = { ...device, ...response };

        if (device.initSuccessful === undefined) {
            device.initSuccessful = (device.signingStatus === 'FETCHED_BY_DEVICE');
        }

        try {
            response = yield call(getHTTP, `${systemStateUrl}/devices/${device.serialNumber}/info`);
            const { equipmentId, vin, hwVariant, baseSwVersion, lastActivity } = response;
            device = { ...device, equipmentId, vin, hwVariant, baseSwVersion, lastActivity };
        } catch (error) {
            // ignore errors in the additional calls
            // eslint-disable-next-line no-console
            console.log(error);
        }

        //console.log(device);
        yield put(successfulFetchPropertiesForDevice(device));
    } catch (error) {
        yield fork(doHandleErrorSaga, error);
        yield put(failedFetchPropertiesForDevice(error));
        yield put(showErrorMessage(error));
    }
}

export function* doFetchCertificatesForDevice(action) {
    try {
        const url = yield call(getDeviceServiceURL);
        const response = yield call(getHTTP, `${url}/devices/${action.serialNumber}/certificates`);
        let vcmResponse;
        if (action.vcmSerialNumber) {
            vcmResponse = yield call(getHTTP, `${url}/devices/${action.vcmSerialNumber}/certificates`);
        }
        yield put(successfulFetchCertificatesForDevice(response, vcmResponse));
    } catch (error) {
        yield fork(doHandleErrorSaga, error);
        yield put(failedFetchCertificatesForDevice(error));
        yield put(showErrorMessage(error));
    }
}

export function* doFetchCertificate(action) {
    try {
        const url = yield call(getDeviceServiceURL);
        const response = yield call(requestText, `${url}/devices/certificates/${action.certSerialNumber}/string`, {
            method: 'GET',
            headers: {
                Accept: 'text/plain',
            },
        });
        yield put(successfulFetchCertificate(response));
    } catch (error) {
        yield fork(doHandleErrorSaga, error);
        yield put(failedFetchCertificate(error));
        yield put(showErrorMessage(error));
    }
}

export function* fetchDevicePropertiesSaga() {
    yield takeLatest(FETCH_DEVICE_PROPERTIES, doFetchDeviceProperties);
}
export function* fetchDevicesCertificatesSaga() {
    yield takeLatest(FETCH_DEVICE_CERTIFICATES, doFetchCertificatesForDevice);
}
export function* fetchCertificateSaga() {
    yield takeLatest(FETCH_CERTIFICATE, doFetchCertificate);
}
