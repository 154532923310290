import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { doHandleErrorSaga, getHTTP } from '../sagaUtil';
import { failedFetchFilteredDevices } from '../../actions/showResultActions';
import { showErrorMessage } from '../../actions/ui/notificationsActions';
import { getDeviceBackend } from '../../selectors/backendSelectors';
import {
    FETCH_STONERIDGE_JOURNAL_RECORDS,
    successfulFetchStoneridgeJournalRecords,
} from '../../actions/journal/stoneridgeJournalActions';

export function* getServiceURL() {
    const serviceURL = yield select(getDeviceBackend);
    return `${serviceURL}`;
}

export function* doFetchStoneridgeJournalRecords(action) {
    try {
        const url = yield call(getServiceURL);
        const response = yield call(getHTTP, `${url}/stoneridge-import-history?page=${action.pageToShow}`);
        yield put(successfulFetchStoneridgeJournalRecords(response));
    } catch (error) {
        yield fork(doHandleErrorSaga, error);
        yield put(failedFetchFilteredDevices(error));
        yield put(showErrorMessage(error));
    }
}

export function* fetchStoneridgeJournalRecordsSaga() {
    yield takeLatest(FETCH_STONERIDGE_JOURNAL_RECORDS, doFetchStoneridgeJournalRecords);
}
