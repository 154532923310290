import noop from 'lodash/noop';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { DateRangePicker, Multiselect } from 'rio-uikit';
import moment from 'moment';

/**
 * System State Filter component
 */
export class SystemStateFilter extends Component {

  render() {
    const { intl } = this.props;
    const messageTypeFilterOptions = [
      { id: 'notifTypes', header: true },
      { id: 'CPU_LIMIT' },
      { id: 'MEM_LIMIT' },
      { id: 'APP_CRASH' },
      { id: 'PROC_NOT_RUNNING' },
      { id: 'BOOT_BANK_SWITCHED' },
      { id: 'AUTO_BANK_SWITCH' },
      { id: 'REQUESTED' },
      { id: 'STATISTICAL' },
    ];
    messageTypeFilterOptions.forEach(
      e => { e.label = intl.formatMessage({ id: `intl-msg:${e.id}` }); }
    );

    return (
      <span className='input-group'>
        <div className='input-group-btn'>
          <Multiselect placeholder={'Please select...'}
            options={messageTypeFilterOptions}
            value={this.props.selectedMessageTypeFilter}
            onChange={(s) => this.props.onSelectMessageTypeFilter(s)} />
        </div>
        <DateRangePicker hasTimePicker locale={`${intl.locale}`} textDefault={intl.formatMessage({ id: 'intl-msg:selectTimeRange' })}
          textToday={intl.formatMessage({ id: 'intl-msg:today' })}
          textLastWeek={intl.formatMessage({ id: 'intl-msg:oneWeek' })}
          textLastMonth={intl.formatMessage({ id: 'intl-msg:oneMonth' })}
          textCustom={intl.formatMessage({ id: 'intl-msg:customRange' })}
          textFrom={intl.formatMessage({ id: 'intl-msg:from' })}
          textTo={intl.formatMessage({ id: 'intl-msg:to' })}
          textApply={intl.formatMessage({ id: 'intl-msg:ok' })}
          textCancel={intl.formatMessage({ id: 'intl-msg:cancel' })}
          customPresets={[{
            text: intl.formatMessage({ id: 'intl-msg:today' }),
            startValue: moment().startOf('day'),
            endValue: moment().endOf('day'),
          }, {
            text: intl.formatMessage({ id: 'intl-msg:oneWeek' }),
            startValue: moment().startOf('day').subtract(1, 'weeks'),
            endValue: moment().endOf('day'),
          }, {
            text: intl.formatMessage({ id: 'intl-msg:oneMonth' }),
            startValue: moment().startOf('day').subtract(1, 'months'),
            endValue: moment().endOf('day'),
          }]}
          startValue={this.props.startValue} endValue={this.props.endValue}
          onRangeChange={(startValue, endValue) => this.props.onTimeRangeChange(startValue, endValue)} />
        <div className='btn-toolbar'>
          <button className='btn btn-primary' onClick={this.props.fetchSystemStateNotifications}>{this.props.searchButtonText}</button>
          {
            this.props.requestStateNotificationFromDevice != noop &&
            this.props.selectedMessageTypeFilter.includes('REQUESTED') &&
            <button className='btn btn-primary' onClick={this.props.requestStateNotificationFromDevice}>
              <FormattedMessage id='intl-msg:requestSystemState' />
            </button>
          }
          <button className='btn btn-primary' onClick={this.props.csvExportSystemState}>Export</button>
        </div>
      </span>
    );
  }
}

export default injectIntl(SystemStateFilter);

SystemStateFilter.defaultProps = {
  intl: {
    formatMessage: noop,
  },
  csvExportSystemState: noop,
  fetchSystemStateNotifications: noop,
  requestStateNotificationFromDevice: noop,
  onSelectMessageTypeFilter: noop,
  onTimeRangeChange: noop,
};

SystemStateFilter.propTypes = {
  // props
  intl: intlShape.isRequired,
  selectedMessageTypeFilter: PropTypes.array,
  startValue: PropTypes.any,
  endValue: PropTypes.any,
  searchButtonText: PropTypes.node.isRequired,
  // functions
  csvExportSystemState: PropTypes.func.isRequired,
  fetchSystemStateNotifications: PropTypes.func.isRequired,
  requestStateNotificationFromDevice: PropTypes.func.isRequired,
  onSelectMessageTypeFilter: PropTypes.func.isRequired,
  onTimeRangeChange: PropTypes.func.isRequired,
};
