/* eslint-disable no-use-before-define */
import React, { Component } from 'react';
import { DataTabs, DataTab } from 'rio-uikit';
import NasysJournal from './NasysJournal';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import StoneridgeJournal from './StoneridgeJournal';

const NASYS_JOURNAL_TAB = 'nasys_tab';
const STONERIDGE_JOURNAL_TAB = 'stoneridge_tab';

// TODO - add german translations to src/features/lang/translations/de-DE.json
class JournalPage extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.translate = this.translate.bind(this);
    }

    componentDidMount() {
        this.setState({ tabKey: STONERIDGE_JOURNAL_TAB });
    }

    render() {
        return (
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h1><FormattedMessage id='intl-msg:journal'/></h1>
                    </div>
                </div>
                <DataTabs activeKey={this.state.tabKey} onSelectTab={tabKey => this.setState({ tabKey })}>
                    <DataTab
                        tabKey={STONERIDGE_JOURNAL_TAB}
                        title={<Title text={this.translate('intl-msg:journal:stoneridge')}/>}>
                        <StoneridgeJournal/>
                    </DataTab>
                    <DataTab
                        tabKey={NASYS_JOURNAL_TAB}
                        title={<Title text={this.translate('intl-msg:journal:nasys')}/>}>
                        <NasysJournal/>
                    </DataTab>
                </DataTabs>
            </div>
        );
    }

    translate(id) {
        return this.props.intl.formatMessage({ id: id });
    }
}

export const mapStateToProps = (state) => ({});

export const mapDispatchToProps = (state) => ({});

export const Title = ({ text, value }) => (
    <div>
        <div>{text}</div>
        <div className='text-bold text-size-larger'>{value}</div>
    </div>
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(JournalPage)));
