/**
 * Action type for showing error messages.
 * @type {string}
 */
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';

/**
 * Action creator to show an error message.
 * @param {string} error The error returned from the server.
 * @returns {object} a show error message action object.
 */
export function showErrorMessage(error) {
    return {
        type: SHOW_ERROR_MESSAGE,
        error,
    };
}
