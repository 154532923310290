import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { SpinnerInfoBox } from 'rio-uikit';
import ListTable from './table/ListTable';
import {
    SERVICE_CERTIFICATES_TABLE_COLUMN_DESCRIPTORS,
} from './table/ColumnDescriptors';
import { getProdCertificateRecords, getDevCertificateRecords, getLoadingState } from '../selectors/serviceCertificateSelectors';
import { fetchCertificateRecords } from '../actions/serviceCertificateActions';
import sortBy from 'lodash/sortBy';

class CertificatePage extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h1>{'Prod service certificates'}</h1>
                        <div>
                            { this.props.loading ? (
                                <SpinnerInfoBox text={'Loading'} isInverse={false}/>
                            ) : (
                                <ListTable items={sortBy(this.props.prodCertificates,'remainingDays')}
                                    className={'table-sticky-in-container table-condensed'} rowClassname={this.rowstyle}
                                    columnDescriptors={SERVICE_CERTIFICATES_TABLE_COLUMN_DESCRIPTORS}/>)
                            }
                        </div>
                        <h1>{'Dev service certificates'}</h1>
                        <div>
                            { this.props.loading ? (
                                <SpinnerInfoBox text={'Loading'} isInverse={false}/>
                            ) : (
                                <ListTable items={sortBy(this.props.devCertificates,'remainingDays')}
                                    className={'table-sticky-in-container table-condensed'} rowClassname={this.rowstyle}
                                    columnDescriptors={SERVICE_CERTIFICATES_TABLE_COLUMN_DESCRIPTORS}/>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    rowstyle(row) {
        let days = row['remainingDays'];
        return (days < 15) ? 'danger' : ((days < 30) ? 'warning' : '');
    }

    componentDidMount() {
        this.props.fetchCertificateRecords();
    }
}

export const mapStateToProps = (state) => ({
    loading: getLoadingState(state),
    prodCertificates: getProdCertificateRecords(state),
    devCertificates: getDevCertificateRecords(state),
});

export const mapDispatchToProps = (dispatch) => ({
    fetchCertificateRecords: () => {
        dispatch(fetchCertificateRecords());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CertificatePage));
