import { call, takeEvery } from 'redux-saga/effects';
import { Notification } from 'rio-uikit';
import { HTTP_STATUS_NOT_FOUND, HTTP_STATUS_FORBIDDEN } from './../../constants/httpErrors';

import {
    SHOW_ERROR_MESSAGE,
} from './../../actions/ui/notificationsActions';

export const doShowNotification = error => {
    if (error) {
        if (error.status === HTTP_STATUS_NOT_FOUND) {
            Notification.error('Device not found', error.name);
        } else if (error.status === HTTP_STATUS_FORBIDDEN) {
            Notification.error('You are missing the role for this operation', error.body);
        } else {
            let message;
            try {
                message = error.body ? JSON.parse(error.body).message : error.message;
            } catch (ex) {
                console.log(ex);
                console.log(error.body);
                message = error.body;
            }
            Notification.error(message, error.name, 10000, () => {
                // eslint-disable-next-line no-alert
                alert(message);
            });
        }
    }
};

export function* doShowErrorMessage(action) {
    const error = action.error;
    yield call(doShowNotification, error);
}

export function* showErrorMessagesSaga() {
    yield takeEvery(SHOW_ERROR_MESSAGE, doShowErrorMessage);
}
