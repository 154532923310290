const getDeviceInfo = state => state.deviceMonitor.systemState;

export const getLoadingOfDeviceInfo = state => getDeviceInfo(state).loading;
export const getDeviceStateNotifications = state => getDeviceInfo(state).deviceStateNotifications;
export const getStateNotificationsOfDevice = state => getDeviceInfo(state).stateNotificationsOfDevice;
export const getSelectedMessageTypeFilter = state => getDeviceInfo(state).selectedMessageTypeFilter;
export const getStartDateTime = state => getDeviceInfo(state).startDateTime;
export const getEndDateTime = state => getDeviceInfo(state).endDateTime;
export const getBaseSwVersion = state => getDeviceInfo(state).baseSwVersion;
export const getSystemStateVersion = state => getDeviceInfo(state).systemStateVersion;
export const getCurrentPage = state => getDeviceInfo(state).currentPage;
export const getLastPage = state => getDeviceInfo(state).isLastPage;
