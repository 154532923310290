export const FETCH_STONERIDGE_JOURNAL_RECORDS = 'FETCH_STONERIDGE_JOURNAL_RECORDS';
export const SUCCESSFUL_STONERIDGE_FETCH_JOURNAL_RECORDS = 'SUCCESSFUL_STONERIDGE_FETCH_JOURNAL_RECORDS';

export const fetchStoneridgeJournalRecords = (pageToShow) => {
    return {
        type: FETCH_STONERIDGE_JOURNAL_RECORDS,
        pageToShow,
    };
};

export const successfulFetchStoneridgeJournalRecords = (response) => {
    return {
        type: SUCCESSFUL_STONERIDGE_FETCH_JOURNAL_RECORDS,
        payload: response,
    };
};
