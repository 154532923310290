export const CSV_EXPORT_DEVICES_SYSTEM_STATE = 'CSV_EXPORT_DEVICES_SYSTEM_STATE';
export const SUCCESSFUL_CSV_EXPORT_DEVICES_SYSTEM_STATE = 'SUCCESSFUL_CSV_EXPORT_DEVICES_SYSTEM_STATE';
export const REQUEST_STATE_NOTIFICATIONS_FROM_DEVICE = 'REQUEST_STATE_NOTIFICATIONS_FROM_DEVICE';
export const FETCH_DEVICE_STATE_NOTIFICATIONS = 'FETCH_DEVICE_STATE_NOTIFICATIONS';
export const SUCCESSFUL_FETCH_DEVICE_STATE_NOTIFICATIONS = 'SUCCESSFUL_FETCH_DEVICE_STATE_NOTIFICATIONS';
export const FAILED_FETCH_DEVICE_STATE_NOTIFICATIONS = 'FAILED_FETCH_DEVICE_STATE_NOTIFICATIONS';
export const RANGE_CHANGE_DEVICE_STATE_NOTIFICATIONS = 'RANGE_CHANGE_DEVICE_STATE_NOTIFICATIONS';
export const SELECT_MESSAGE_TYPE_FILTER = 'SELECT_MESSAGE_TYPE_FILTER';

export const FETCH_STATE_NOTIFICATIONS_OF_DEVICE = 'FETCH_STATE_NOTIFICATIONS_OF_DEVICE';
export const SUCCESSFUL_FETCH_STATE_NOTIFICATIONS_OF_DEVICE = 'SUCCESSFUL_FETCH_STATE_NOTIFICATIONS_OF_DEVICE';
export const FAILED_FETCH_STATE_NOTIFICATIONS_OF_DEVICE = 'FAILED_FETCH_STATE_NOTIFICATIONS_OF_DEVICE';

export const fetchDeviceStateNotifications = (messageFilter, startTimestamp, endTimestamp, nextPage) => {
    return {
        type: FETCH_DEVICE_STATE_NOTIFICATIONS,
        messageFilter,
        startTimestamp,
        endTimestamp,
        nextPage,
    };
};
export const successfulFetchDeviceStateNotifications = (payload) => {
    return {
        type: SUCCESSFUL_FETCH_DEVICE_STATE_NOTIFICATIONS,
        payload,
    };
};
export const failedFetchDeviceStateNotifications = (error) => {
    return {
        type: FAILED_FETCH_DEVICE_STATE_NOTIFICATIONS,
        error,
    };
};

export const requestStateNotificationFromDevice = (serialNumber) => {
    return {
        type: REQUEST_STATE_NOTIFICATIONS_FROM_DEVICE,
        serialNumber,
    };
};

export const fetchStateNotificationsOfDevice = (serialNumber, messageFilter, startTimestamp, endTimestamp) => {
    return {
        type: FETCH_STATE_NOTIFICATIONS_OF_DEVICE,
        serialNumber,
        messageFilter,
        startTimestamp,
        endTimestamp,
    };
};
export const successfulFetchStateNotificationsOfDevice = (payload) => {
    return {
        type: SUCCESSFUL_FETCH_STATE_NOTIFICATIONS_OF_DEVICE,
        payload,
    };
};
export const failedFetchStateNotificationsOfDevice = (error) => {
    return {
        type: FAILED_FETCH_STATE_NOTIFICATIONS_OF_DEVICE,
        error,
    };
};
export const onRangeChange = (startValue, endValue) => {
    return {
        type: RANGE_CHANGE_DEVICE_STATE_NOTIFICATIONS,
        startValue, endValue,
    };
};
export const onSelectMessageTypeFilter = (newSelectedMessageFilter) => {
    return {
        type: SELECT_MESSAGE_TYPE_FILTER,
        newSelectedMessageFilter,
    };
 };

export const csvExportSystemState = (serialNumber, messageFilter, startTimestamp, endTimestamp) => {
    return {
        type: CSV_EXPORT_DEVICES_SYSTEM_STATE,
        serialNumber,
        messageFilter,
        startTimestamp,
        endTimestamp,
    };
};

export const successfulExportFilteredDevicesSystemState = (exportDevicesData, filename) => {
    return {
        type: SUCCESSFUL_CSV_EXPORT_DEVICES_SYSTEM_STATE,
        exportDevicesData,
        filename,
    };
};


