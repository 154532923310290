import { call, put, select, fork, takeLatest } from 'redux-saga/effects';
import { DeviceType } from '../constants/deviceConstants';

import {
    SEARCH_DEVICES, failedSearchDevices,
    FETCH_FILTERED_DEVICES, successfulFetchFilteredDevices, failedFetchFilteredDevices,
    CSV_EXPORT_DEVICES, successfulExportFilteredDevices,
} from './../actions/showResultActions';

import { showErrorMessage } from './../actions/ui/notificationsActions';
import {
    getDeviceMonitoringBackend, getDeviceBackend,
    getDeviceSystemStateBackend,
} from './../selectors/backendSelectors';

import { doHandleErrorSaga, getHTTP, putHTTP } from './sagaUtil';

export function* getServiceURL() {
    const serviceURL = yield select(getDeviceMonitoringBackend);
    return `${serviceURL}`;
}

export function* getDeviceServiceURL() {
    const serviceURL = yield select(getDeviceBackend);
    return `${serviceURL}`;
}

export function* getDeviceSystemStateBackendURL() {
    const serviceURL = yield select(getDeviceSystemStateBackend);
    return `${serviceURL}`;
}

export function onShowDevice({ serialNumber }, history) {
    const route = `/singledeviceview/${serialNumber}`;
    history.push(route);
}

function getSearchCategory({ searchCategory, searchPattern, deviceType }) {
    const hwSerialRegEx = /^(\d){6}-(\d){4}$/;
    if (searchCategory === 'none') {
        if (searchPattern.length === 17) {
            return 'vin17';
        } else if (searchPattern.length === 7) {
            return 'vin7';
        } else if (searchPattern.length === 20) {
            return 'iccid';
        } else if (hwSerialRegEx.test(searchPattern)) {
            return deviceType === DeviceType.VCM ? 'vcmserialnumber' : 'serialnumber';
        }
        throw new Error('Search category not defined!');
    }
    return searchCategory;
}

function scanDeviceType({ searchCategory, searchPattern, deviceType }) {
    const hwSerialWithTypeRegEx = /^(VCM|TBM)(\d){6}-(\d){4}$/;
    let newSearchPattern = searchPattern;
    let newDeviceType = deviceType;
    if (hwSerialWithTypeRegEx.test(searchPattern)) {
        newDeviceType = searchPattern.substring(0, 3);
        newSearchPattern = searchPattern.substring(3);
        if (newDeviceType === 'TBM') {
            newDeviceType = DeviceType.TBM3;
        }
    } else if (!deviceType) {
        newDeviceType = DeviceType.TBM3;
    }

    const newSearchCategory = getSearchCategory({ searchCategory, searchPattern: newSearchPattern, deviceType: newDeviceType });
    return { searchCategory: newSearchCategory, searchPattern: newSearchPattern, deviceType: newDeviceType };
}

function* lookupDevice(action) {
    const deviceUrl = yield call(getDeviceServiceURL);
    const searchCategory = getSearchCategory(action);
    let url = 'none';
    switch (searchCategory) {
        case 'imsi':
        case 'imei':
        case 'iccid':
        case 'serialnumber':
        case 'vcmserialnumber': {
            url = `${deviceUrl}/devices/${action.searchPattern}/ddm?identifier=${searchCategory}`;
            let response = yield call(getHTTP, url);
            if (Array.isArray(response)) {
                response = response[0];
            }
            return { ...response };
        }
        default:
            return undefined;
    }
}

function* lookupVehicle(action) {
    const nasysUrl = yield call(getServiceURL);
    const deviceSystemStateUrl = yield call(getDeviceSystemStateBackendURL);
    const searchCategory = getSearchCategory(action);
    let url = 'none';
    switch (searchCategory) {
        case 'vin7':
        case 'vin17': {
            url = `${nasysUrl}/devices/${searchCategory}/${action.searchPattern}`;
            let response = yield call(getHTTP, url);
            if (Array.isArray(response)) {
                response = response[0];
            }
            return { ...response };
        }
        case 'currentVin': {
            url = `${deviceSystemStateUrl}/devices/info/vin`;
            let response = yield call(putHTTP, url, `["${action.searchPattern}"]`);
            if (Array.isArray(response)) {
                response = response[0];
            }
            return { ...response };
        }
        default:
            return undefined;
    }
}

export function* doSearchDevices(action) {
    try {
        const searchParams = scanDeviceType(action);
        let device = yield lookupDevice(searchParams);
        if (!device) {
            device = yield lookupVehicle(searchParams);
        }

        //console.log(device);
        yield onShowDevice(device, action.history);
    } catch (error) {
        yield fork(doHandleErrorSaga, error);
        yield put(failedSearchDevices(error));
        yield put(showErrorMessage(error));
    }
}

function getFilterUrl(url, dataState) {
    switch (dataState) {
        case 'nosuccess':
            return `${url}/devices/no-success-init`;
        case 'success':
            return `${url}/devices/with-success-init`;
        case 'replaced':
            return `${url}/devices/replaced`;
        case 'lost':
            return `${url}/devices/lost`;
        default:
            return `${url}/devices`;
    }
}

export function* doFetchFilteredDevices(action) {
    try {
        let url = yield call(getServiceURL);

        const devicesInfo = (action.pageToShow === 0) ? yield call(getHTTP, `${url}/devices/info`) : {};
        url = getFilterUrl(url, action.dataState);
        const response = yield call(getHTTP, `${url}?page=${action.pageToShow}`);
        yield put(successfulFetchFilteredDevices({
            ...response,
            devicesInfo,
        }));
    } catch (error) {
        yield fork(doHandleErrorSaga, error);
        yield put(failedFetchFilteredDevices(error));
        yield put(showErrorMessage(error));
    }
}

function createDownloadForDataSet(dataSetOfDevicesToDownload) {
    const allDevicesInDataSet = [];
    allDevicesInDataSet.push('Vin7;Vin17;IMEI;ICCID;Serialnumber;Created;CertifiactionTime;InitSuccessful;InitState;InitError;Fleetdata\r\n');
    for (const oneTbm of dataSetOfDevicesToDownload) {
        allDevicesInDataSet.push(`${oneTbm.vin7};${oneTbm.vin17};${oneTbm.imei};${oneTbm.iccid};${oneTbm.serialNumber};${oneTbm.created};${oneTbm.timestamp};${oneTbm.initSuccessful};${oneTbm.initState};${oneTbm.errorState};${oneTbm.vehicleInit}\r\n`);
    }
    const allDevicesCombined = allDevicesInDataSet.join('');
    return allDevicesCombined;
}

function getExportFilename(dataState) {
    switch (dataState) {
        case 'nosuccess':
            return 'NotSignedDevices.csv';
        case 'success':
            return 'SuccessfulSignedDevices.csv';
        case 'replaced':
            return 'ReplacedDevices.csv';
        case 'lost':
            return 'LostDevices.csv';
        default:
            return 'AllDevices.csv';
    }
}

export function* doExportFilteredDevices(action) {
    try {
        let url = yield call(getServiceURL);
        url = getFilterUrl(url, action.dataState);

        const pageSize = 10000;
        let response = yield call(getHTTP, `${url}?size=${pageSize}&page=0`);
        const totalPages = response.totalPages;
        let dataSetOfDevicesToDownload = response.content.slice();
        for (let page = 1; page < totalPages; page++) {
            response = yield call(getHTTP, `${url}?size=${pageSize}&page=${page}`);
            dataSetOfDevicesToDownload = dataSetOfDevicesToDownload.concat(response.content);
        }
        const exportDevicesData = createDownloadForDataSet(dataSetOfDevicesToDownload);
        const filename = getExportFilename(action.dataState);
        yield put(successfulExportFilteredDevices(exportDevicesData, filename));
    } catch (error) {
        yield fork(doHandleErrorSaga, error);
        yield put(showErrorMessage(error));
    }
}

export function* searchDevicesSaga() {
    yield takeLatest(SEARCH_DEVICES, doSearchDevices);
}

export function* fetchFilteredDevicesSaga() {
    yield takeLatest(FETCH_FILTERED_DEVICES, doFetchFilteredDevices);
}

export function* exportFilteredDevicesSaga() {
    yield takeLatest(CSV_EXPORT_DEVICES, doExportFilteredDevices);
}
