import noop from 'lodash/noop';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intlShape, injectIntl } from 'react-intl';

import { SingleButtonDropdown, ClearableInput } from 'rio-uikit';
import { DeviceType } from '../constants/deviceConstants';

import { env } from '~/env';
const { features } = env.runtimeConfig;

/**
 * Device finder component
 */
export class ExtDeviceFinder extends Component {

    constructor() {
        super();
        this.state = {
            searchCategory: 'none',
            searchPattern: '',
        };

        this.searchPatternChanged = this.searchPatternChanged.bind(this);
    }

    onSubmit(e) {
        //console.log('started searching');
        e.preventDefault();
        const { history } = this.props;
        this.props.searchDevices(this.state.searchCategory, this.state.searchPattern, DeviceType.TBM3, history);
    }

    render() {
        const { intl } = this.props;
        return (
            <form className='form-horizontal' onSubmit={(e) => this.onSubmit(e)}>
                <div className='input-group'>
                    <div className='input-group-btn'>
                        <SingleButtonDropdown bsStyle='primary'
                            title={intl.formatMessage({ id: `intl-msg:searchCategory-${this.state.searchCategory}` })}
                            items={[{
                                value: intl.formatMessage({ id: 'intl-msg:searchCategory-serialnumber' }),
                                disabled: this.props.loading,
                                onSelect: () => this.setState({ searchCategory: 'serialnumber' }),
                            }, {
                                value: intl.formatMessage({ id: 'intl-msg:searchCategory-vin7' }),
                                disabled: this.props.loading || !features.nasysAvailable,
                                onSelect: () => this.setState({ searchCategory: 'vin7' }),
                            }, {
                                value: intl.formatMessage({ id: 'intl-msg:searchCategory-vin17' }),
                                disabled: this.props.loading || !features.nasysAvailable,
                                onSelect: () => this.setState({ searchCategory: 'vin17' }),
                            }, {
                                value: intl.formatMessage({ id: 'intl-msg:searchCategory-currentVin' }),
                                disabled: this.props.loading,
                                onSelect: () => this.setState({ searchCategory: 'currentVin' }),
                            }, {
                                value: intl.formatMessage({ id: 'intl-msg:searchCategory-imei' }),
                                disabled: this.props.loading,
                                onSelect: () => this.setState({ searchCategory: 'imei' }),
                            }, {
                                value: intl.formatMessage({ id: 'intl-msg:searchCategory-imsi' }),
                                disabled: this.props.loading,
                                onSelect: () => this.setState({ searchCategory: 'imsi' }),
                            }, {
                                value: intl.formatMessage({ id: 'intl-msg:searchCategory-iccid' }),
                                disabled: this.props.loading,
                                onSelect: () => this.setState({ searchCategory: 'iccid' }),
                            }]} />
                    </div>
                    <ClearableInput value={this.state.searchPattern} onChange={this.searchPatternChanged} />
                    <span className='input-group-btn'>
                        <button className='btn btn-primary' type='button' onClick={(e) => this.onSubmit(e)}>
                            <span className='rioglyph rioglyph-search'></span>
                        </button>
                    </span>
                </div>
            </form>
        );
    }

    searchPatternChanged(newValue) {
        //console.log(newValue);
        this.setState({ searchPattern: newValue });
    }

}

export default injectIntl(ExtDeviceFinder);

ExtDeviceFinder.defaultProps = {
    intl: {
        formatMessage: noop,
    },
    searchDevices: noop,
};

ExtDeviceFinder.propTypes = {
    // props
    intl: intlShape.isRequired,
    // functions
    searchDevices: PropTypes.func.isRequired,
};
