import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { doHandleErrorSaga, getHTTP } from '../sagaUtil';
import { failedFetchFilteredDevices } from '../../actions/showResultActions';
import { showErrorMessage } from '../../actions/ui/notificationsActions';
import { getDeviceMonitoringBackend } from '../../selectors/backendSelectors';
import { FETCH_JOURNAL_RECORDS, successfulFetchJournalRecords } from '../../actions/journal/nasysJournalActions';

export function* getServiceURL() {
    const serviceURL = yield select(getDeviceMonitoringBackend);
    return `${serviceURL}`;
}

export function* doFetchNasysJournalRecords(action) {
    try {
        const url = yield call(getServiceURL);

        const nasysJournal = (action.pageToShow === 0) ? yield call(getHTTP, `${url}/nasys-file-history`) : {};
        const response = yield call(getHTTP, `${url}/nasys-file-history?page=${action.pageToShow}`);
        yield put(successfulFetchJournalRecords({
            ...response,
            nasysJournal,
        }));
    } catch (error) {
        yield fork(doHandleErrorSaga, error);
        yield put(failedFetchFilteredDevices(error));
        yield put(showErrorMessage(error));
    }
}

export function* fetchNasysJournalRecordsSaga() {
    yield takeLatest(FETCH_JOURNAL_RECORDS, doFetchNasysJournalRecords);
}
