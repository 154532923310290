const getShowResult = state => state.deviceMonitor.showResult;
const getShowResultPaging = state => getShowResult(state).paging;

export const getDevicesOfShowResult = state => getShowResult(state).devices;
export const getLoadingOfShowResult = state => getShowResult(state).loading;
export const getSuccessDevicesOfShowResult = state => getShowResult(state).successDevices;
export const getNoSuccessDevicesOfShowResult = state => getShowResult(state).noSuccessDevices;
export const getLostDevicesOfShowResult = state => getShowResult(state).lostDevices;
export const getReplacedDevicesOfShowResult = state => getShowResult(state).replacedDevices;

export const getCurrentPageOfShowResult = state => getShowResultPaging(state).page;
export const getTotalPagesOfShowResult = state => getShowResultPaging(state).pages;
export const getTotalDevicesOfShowResult = state => getShowResultPaging(state).total;
