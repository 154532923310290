import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FilePicker, SpinnerInfoBox } from 'rio-uikit';

import { DeviceInfo, SREDeviceInfo } from '../DeviceInformation';
import { deleteDatabase, checkDevicesAndAddResultsToDb, uploadSREDataToDb, setMessage } from '../actions/uploadActions';
import { successfulExportFilteredDevices } from '../actions/showResultActions'

import { getLoadingOfUpload, getMessageOfUpload, getMessageTypeOfUpload } from '../selectors/uploadSelectors';
import ListTable from './table/ListTable';
import {
    NASYS_UPLOAD_TABLE_COLUMN_DESCRIPTORS,
    STONERIDGE_UPLOAD_TABLE_COLUMN_DESCRIPTORS
} from './table/ColumnDescriptors';

class UploadPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkingEnabled: false,
            newTBM3S: [],
            errorTBM3S: [],
            isNasys: true,
            selectedFileName: '',
        };
    }

    render() {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h1>{'Upload NASYS or Stoneridge data'}</h1>
                        <br/>
                        <div className='pull-left'>
                            <FilePicker className='btn-primary' multiple={false} accept='.csv' label='Select file'
                                onPick={(acceptedFiles) => this.readFile(acceptedFiles)}/>
                        </div>
                        <div className='pull-left margin-left-10'>
                            <button className='btn btn-primary'
                                onClick={this.checkDeviceCertificateState}
                                disabled={!this.state.checkingEnabled}>
                                {!this.state.checkingEnabled && 'Upload' || this.state.isNasys && 'Upload NASYS data' || 'Upload Stoneridge data'}
                            </button>
                        </div>
                        <div className='pull-left margin-left-10'>
                            <p> {this.state.selectedFileName} </p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-8'>
                        <br/>
                        {
                            this.props.loading &&
                            <div>
                                <SpinnerInfoBox text={'Loading'} isInverse={false}/>
                            </div>
                        }
                        <div className='module-content'>
                            {this.props.message && <div className={`bs-callout ${this.props.messageType}`}>
                                <h4>
                                    {this.props.message.split('\n').map((item) => {
                                        return (<span> {item} <br /> </span>);
                                    })}
                                </h4>
                            </div>
                            }
                        </div>
                        <br/>
                    </div>
                </div>
                {
                    this.state.isNasys &&
                    <div>
                        <div>
                            <ListTable items={this.state.newTBM3S}
                                       itemKey={'Vin7'}
                                       columnDescriptors={NASYS_UPLOAD_TABLE_COLUMN_DESCRIPTORS}/>
                        </div>
                        <br />
                        <div>
                            <h4>{'Filtered lines'}</h4>
                            <ListTable items={this.state.errorTBM3S}
                                       itemKey={'Vin7'}
                                       columnDescriptors={NASYS_UPLOAD_TABLE_COLUMN_DESCRIPTORS}/>
                        </div>
                    </div> ||
                    <div>
                        <div>
                            <button className='btn btn-primary' onClick={this.csvExportImportLines}
                                disabled={this.props.loading}>
                                {'Export'}
                            </button>
                            <ListTable items={this.state.newTBM3S}
                                       itemKey={'ID'}
                                       columnDescriptors={STONERIDGE_UPLOAD_TABLE_COLUMN_DESCRIPTORS}/>
                        </div>
                        <br />
                        <div>
                            <h4>{'Filtered lines'}</h4>
                            <button className='btn btn-primary' onClick={this.csvExportFilterdLines}
                                disabled={this.props.loading}>
                                {'Export'}
                            </button>
                            <ListTable items={this.state.errorTBM3S}
                                       itemKey={'ID'}
                                       columnDescriptors={STONERIDGE_UPLOAD_TABLE_COLUMN_DESCRIPTORS}/>
                        </div>
                    </div>
                }
            </div>
        );
    }

    csvExportImportLines = (event) => {
        event.preventDefault();
        const filenameImported = this.state.selectedFileName.replace('.csv', '-imported.csv')
        let csvImported = '';
        this.state.newTBM3S.forEach((row) => {
            csvImported += row.join(';');
            csvImported += '\n';
        });
        this.props.csvExport(csvImported, filenameImported);
    }

    csvExportFilterdLines = (event) => {
        event.preventDefault();
        const filename = this.state.selectedFileName.replace('.csv', '-filterd.csv')
        let csv = this.state.newTBM3S[0].join(';') + '\n';
        this.state.errorTBM3S.forEach((row) => {
            csv += row.join(';');
            csv += '\n';
        });
        this.props.csvExport(csv, filename);
    }

    checkDeviceCertificateState = () => {
        this.setState({
            checkingEnabled: false,
        });

        this.props.setMessage('checking started...', 'bs-callout-primary')
        if (this.state.newTBM3S.length > 0) {
            if (this.state.isNasys) {
                this.props.checkDevicesAndAddResultsToDb(this.state.newTBM3S);
            }
            else {
                this.props.uploadSREDataToDb(this.state.newTBM3S, this.state.selectedFileName);
            }
        } else {
            this.props.setMessage('No data to upload', 'bs-callout-primary')
            this.setState({
                newTBM3S: [],
                errorTBM3S: [],
                actionActive: false,
            });
        }
    }

    getAllTbm3sOutOfReadFile = (result) => {
        const tbmInformationLines = result.split(/\r|\n/);
        //console.log('All read stuff', tbmInformationLines);
        //console.log('Zeilen: ', lines.length);
        const tbmInformation = tbmInformationLines[0].split(';');

        if (tbmInformation.length === 5) {
            this.getAllTbm3sOutOfNasysReadFile(tbmInformationLines);
        }
        else if (tbmInformation.length === 30) {
            this.getAllTbm3sOutOfSREReadFile(tbmInformationLines);
        }
    }

    getAllTbm3sOutOfNasysReadFile = (tbmInformationLines) => {
        let arrResult = [];
        const errResult = [];
        let allNewTbmsAreValid = true;
        let noOfLines = 0;
        let noOfRemovedLines = 0;
        let informUserIfTBM3ParsingWorked;
        //console.log('All read stuff', tbmInformationLines);
        //console.log('Zeilen: ', lines.length);
        for (const tbmInfoLine of tbmInformationLines) {
            if (tbmInfoLine === '') {
                //do nothing, it's an empty line
            } else {
                const tbmInformation = tbmInfoLine.split(';');
                noOfLines++;
                //tbmInformation has to contain 5 entries
                if (tbmInformation.length !== 5) {
                    console.log('That\'s an error: ', tbmInformation);
                    allNewTbmsAreValid = false;
                    informUserIfTBM3ParsingWorked = 'Couldn\'t read file...';
                    arrResult = [];
                    noOfRemovedLines++;
                    break;
                }
                //for Tbm3 the Imei is longer than 12 and is at the third position
                if (tbmInformation[2].length > 12) {
                    //adapt german dateformat to american one :(
                    //console.log('date davor: ', tbmInformation[4]);
                    const day = tbmInformation[4].substring(0, 2);
                    const month = tbmInformation[4].substring(3, 5);
                    const year = tbmInformation[4].substring(6, 10);
                    const standardDateFormat = `${year}-${month}-${day}`;
                    const creationTimeWithoutHours = new Date(standardDateFormat).toISOString();
                    tbmInformation[4] = creationTimeWithoutHours.substring(0, 23);
                    const newTBM = new DeviceInfo(tbmInformation);
                    arrResult.push(newTBM);
                }
                else {
                    noOfRemovedLines++;
                    const newTBM = new DeviceInfo(tbmInformation);
                    errResult.push(newTBM);
                }
            }
        }

        //Check if Results contain valid entries:
        for (const newTbm of arrResult) {
            if (!(newTbm.hasOwnProperty('Vin7')) &&
                !(newTbm.hasOwnProperty('Vin17')) &&
                !(newTbm.hasOwnProperty('Imei')) &&
                !(newTbm.hasOwnProperty('created')) &&
                !(newTbm.hasOwnProperty('Iccid'))) {
                allNewTbmsAreValid = false;
                informUserIfTBM3ParsingWorked = 'Couldn\'t read file...';
                arrResult = [];
                break;
            }
        }

        informUserIfTBM3ParsingWorked = `Read all TBM3s from NASYS file (valid lines: ${arrResult.length} from ${noOfLines}/ removed lines: ${noOfRemovedLines})`;
        this.setState({
            newTBM3S: arrResult,
            errorTBM3S: errResult,
            checkingEnabled: allNewTbmsAreValid,
            isNasys: true,
        });
        this.props.setMessage(informUserIfTBM3ParsingWorked, 'bs-callout-primary')
    }

    getAllTbm3sOutOfSREReadFile = (tbmInformationLines) => {
        let arrResult = [];
        const errResult = [];
        let allNewTbmsAreValid = true;
        let noOfLines = 0;
        let noOfRemovedLines = 0;
        let informUserIfTBM3ParsingWorked;
        //console.log('All read stuff', tbmInformationLines);
        //console.log('Zeilen: ', lines.length);
        for (const tbmInfoLine of tbmInformationLines) {
            if (tbmInfoLine === '') {
                //do nothing, it's an empty line
            } else {
                const tbmInformation = tbmInfoLine.split(';');
                noOfLines++;
                //tbmInformation has to contain 30 entries
                if (tbmInformation.length !== 30) {
                    console.log('That\'s an error: ', tbmInformation);
                    allNewTbmsAreValid = false;
                    informUserIfTBM3ParsingWorked = 'Couldn\'t read file...';
                    arrResult = [];
                    noOfRemovedLines++;
                    break;
                }
                if (noOfLines === 1 || this.verifyLine(tbmInformation)) {
                    const newTBM = new SREDeviceInfo(tbmInformation);
                    arrResult.push(newTBM);
                }
                else {
                    noOfRemovedLines++;
                    const newTBM = new SREDeviceInfo(tbmInformation);
                    errResult.push(newTBM);
                }
            }
        }

        //Check if Results contain valid entries:
        for (const newTbm of arrResult) {
            if (!(newTbm.hasOwnProperty('ID')) &&
                !(newTbm.hasOwnProperty('TBM3_serial_number')) &&
                !(newTbm.hasOwnProperty('IMSI')) &&
                !(newTbm.hasOwnProperty('ICCID')) &&
                !(newTbm.hasOwnProperty('TBM3_IMEI'))) {
                allNewTbmsAreValid = false;
                informUserIfTBM3ParsingWorked = 'Couldn\'t read file...';
                arrResult = [];
                break;
            }
        }

        informUserIfTBM3ParsingWorked = `Read all TBM3s from Stoneridge file (valid lines: ${arrResult.length - 1} from ${noOfLines - 1}/ removed lines: ${noOfRemovedLines})`;
        this.setState({
            newTBM3S: arrResult,
            errorTBM3S: errResult,
            checkingEnabled: allNewTbmsAreValid,
            isNasys: false,
        });
        this.props.setMessage(informUserIfTBM3ParsingWorked, 'bs-callout-primary')
    }

    verifyLine = (tbmInformation) => {
        // remove quotes if needed
        let tbm3SerialNumber = tbmInformation[3].replace(/^"(.*)"$/,'$1');
        let vcmSerialNumber = tbmInformation[9].replace(/^"(.*)"$/,'$1');

        // serialnumber are 10 chars long
        return  tbm3SerialNumber.length === 10 || vcmSerialNumber.length === 10;
    }

    readFile = (acceptedFile) => {
        //console.log('Choosen File', acceptedFile);

        const testResultText = `Not checked yet...\n${this.props.message}`;
        //If no file is choosen after a file was already choosen
        if (!acceptedFile) {
            this.props.setMessage(testResultText, 'bs-callout-primary')
            return;
        }
        this.setState({
            selectedFileName: acceptedFile[0].name,
        });

        const reader = new FileReader();
        const nasysExtract = acceptedFile[0];

        reader.readAsText(nasysExtract);
        reader.onloadend = () => {
            //console.log('result: ', reader.result);
            this.getAllTbm3sOutOfReadFile(reader.result);
        };
    }

}

export const mapStateToProps = (state) => ({
    loading: getLoadingOfUpload(state),
    message: getMessageOfUpload(state),
    messageType: getMessageTypeOfUpload(state),
});

export const mapDispatchToProps = (dispatch) => ({
    deleteDatabase: () => {
        dispatch(deleteDatabase());
    },
    checkDevicesAndAddResultsToDb: (newTBM3s) => {
        dispatch(checkDevicesAndAddResultsToDb(newTBM3s));
    },
    uploadSREDataToDb: (newTBM3s, filename) => {
        dispatch(uploadSREDataToDb(newTBM3s, filename));
    },
    setMessage: (message, messageType) => {
        dispatch(setMessage(message, messageType));
    },
    csvExport: (exportDevicesData, filename) => {
        dispatch(successfulExportFilteredDevices(exportDevicesData, filename));
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadPage));
