import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import UploadPage from './../components/UploadPage';
import ShowResultPage from './../components/ShowResultPage';
import DeviceSystemStatesPage from '../components/DeviceSystemStatesPage';
import DevicesSigningPage from '../components/DevicesSigningPage';
import SystemStateOverviewPage from '../components/SystemStateOverviewPage';
import CheckSingleDevicesPage from './../components/CheckSingleDevicesPage';
import AppContainer from './AppContainer';
import JournalPage from '../components/journal/JournalComponent';
import CertificatePage from "../components/CertificatePage";

const ShowResultsWithPaging = (props) => {
    return (
        <ShowResultPage {...props}/>
    );
};

export class MainContainer extends Component {
    render() {
        return (
            <AppContainer>
                <Switch>
                    <Route path='/systemstate/:serialNumber' render={(props) => {
                        const serialNumber = props.match.params.serialNumber;
                        return <DeviceSystemStatesPage {...props} serialNumber={serialNumber} />;
                    }} />
                    <Route path='/systemstate' component={SystemStateOverviewPage}/>
                    <Route path='/singledeviceview/:serialNumber' render={(props) => {
                        const serialNumber = props.match.params.serialNumber;
                        return <CheckSingleDevicesPage {...props} serialNumberOfDevice={serialNumber}/>;
                    }} />
                    <Route path='/devicesSigning' component={DevicesSigningPage}/>
                    <Route path='/journal' component={JournalPage}/>
                    <Route path='/showresult/:page' component={ShowResultsWithPaging}/>
                    <Route path='/upload' component={UploadPage}/>
                    <Route path='/certificate' component={CertificatePage}/>
                    <Redirect to='/systemstate'/>
                </Switch>
            </AppContainer>
        );
    }
}

export default MainContainer;
