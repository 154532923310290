export const FETCH_CERTIFICATES_RECORDS = 'FETCH_CERTIFICATES_RECORDS';
export const SUCCESSFUL_FETCH_CERTIFICATES_RECORDS = 'SUCCESSFUL_FETCH_CERTIFICATES_RECORDS';

export const fetchCertificateRecords = () => {
    return {
        type: FETCH_CERTIFICATES_RECORDS,
    };
};

export const successfulFetchCertificateRecords = (response) => {
    return {
        type: SUCCESSFUL_FETCH_CERTIFICATES_RECORDS,
        payload: response,
    };
};
