import sortBy from 'lodash/sortBy';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { SimpleDialog } from 'rio-uikit';
import ListTable from './table/ListTable';

import { fetchCertificatesForDevice, fetchCertificate } from '../actions/singleDeviceActions';
import { tbm3CertificatesSelector, vcmCertificatesSelector, getSelectedCertificateSelector } from '../selectors/singleDeviceSelectors';
import { DeviceType } from '../constants/deviceConstants';
import { DEVICE_CERTIFICATES_TABLE_COLUMN_DESCRIPTORS } from './table/ColumnDescriptors';

const yesOrNo = field => {
    const classes = field ? 'rioglyph-ok text-success' : 'rioglyph-remove text-danger';
    return (
        <span className={`rioglyph text-size-large align-middle ${classes}`} />
    );
};

const renderText = (str) => {
    return <pre style={{ whiteSpace: 'pre-wrap' }}>{str}</pre>;
};

/**
 * Container for the device properties
 */
class DeviceCertificatesContainer extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    render() {
        const { tbm3Certificates, vcmCertificates, certificate } = this.props;

        const rows = this.renderRows(tbm3Certificates, vcmCertificates);
        return (
            <div className='col-md-12'>
                <div className='bg-white padding-left-20 padding-right-20 padding-top-20 padding-bottom-20'>
                    <ListTable items={rows}
                        itemKey='certSerialNumber'
                        columnDescriptors={DEVICE_CERTIFICATES_TABLE_COLUMN_DESCRIPTORS}
                        onRowClick={this.onRowSelect}/>
                     <SimpleDialog
                        show={this.state.show}
                        title={'Certificate'}
                        content={renderText(certificate)}
                        onClose={() => this.setState({ show: false })} />
                </div>
            </div>
        );
    }

    componentWillMount() {
        const { serialNumber, device } = this.props;
        const { vcm } = device;
        this.props.fetchCertificatesForDevice(serialNumber, vcm ? vcm.serialNumber : null);
    }

    componentWillUnmount() {
    }

    renderRows = (tbm3Certificates, vcmCertificates) => {
        return sortBy(tbm3Certificates, 'valid').reverse().map((row) =>
            this.renderRow(row, DeviceType.TBM3)
        ).concat(sortBy(vcmCertificates, 'valid').reverse().map((row) =>
            this.renderRow(row, DeviceType.VCM)
        ));
    }

    renderRow = (row, deviceType) => {
        return { ...row, deviceType, valid: yesOrNo(row.valid) };
    }

    onRowSelect = (event) => {
        const itemKey = event.currentTarget.getAttribute('data-key');
        if (itemKey) {
            this.props.fetchCertificate(itemKey);
            this.setState({ show: true });
        }
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        tbm3Certificates: tbm3CertificatesSelector(state, ownProps),
        vcmCertificates: vcmCertificatesSelector(state, ownProps),
        certificate: getSelectedCertificateSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchCertificatesForDevice: (serialNumber, vcmSerialNumber) => {
            dispatch(fetchCertificatesForDevice(serialNumber, vcmSerialNumber));
        },
        fetchCertificate: certSerialNumber => {
            dispatch(fetchCertificate(certSerialNumber));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceCertificatesContainer);

DeviceCertificatesContainer.defaultProps = {
    // props
    serialNumber: '',
    device: {},
    // functions
    fetchCertificatesForDevice: noop,
    fetchCertificate: noop,
};

DeviceCertificatesContainer.propTypes = {
    // props
    serialNumber: PropTypes.string,
    device: PropTypes.object,
    // functions
    fetchCertificatesForDevice: PropTypes.func,
    fetchCertificate: PropTypes.func,
};
