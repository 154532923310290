import { FETCH_CERTIFICATES_RECORDS, SUCCESSFUL_FETCH_CERTIFICATES_RECORDS} from "../actions/serviceCertificateActions";

const DEFAULT_STATE = {
    loading: false,
    certificateRecords: [],
};

const setLoading = (state, loading) => {
    return {
        ...state,
        loading,
    };
};

function successfulFetchCertificateRecords(state, action) {
    let certificateRecords = action.payload;
    let prodCertificates = [];
    let devCertificates = [];
    if (Object.keys(certificateRecords).length > 0) {
        Object.values(certificateRecords).forEach((cert) => {
            cert.expirationDate = new Intl.DateTimeFormat('GB').format(new Date(cert.expirationDate));
            if (cert.remainingDays <= 0) {
                cert.remainingDays = 'Expired';
            }
            cert.prod ? prodCertificates.push(cert) : devCertificates.push(cert);
        });
    }
    return {
        ...state,
        ...action.payload,
        loading: false,
        prodCertificates,
        devCertificates
    };
}

export function certificateReducer(state = DEFAULT_STATE, action = {}) {
    switch (action.type) {
        case FETCH_CERTIFICATES_RECORDS:
            return setLoading(state, true);
        case SUCCESSFUL_FETCH_CERTIFICATES_RECORDS:
            return successfulFetchCertificateRecords(state, action);
        default:
            return state;
    }
}

export default certificateReducer;
