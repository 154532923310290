import map from 'lodash/map';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { deviceRowDescriptors, deviceVcmRowDescriptors } from './../constants/rowDescriptors';
import { fetchPropertiesForDevice } from '../actions/singleDeviceActions';
import { getDevicePropertiesSelector } from '../selectors/singleDeviceSelectors';

/**
 * Container for the device properties
 */
class DevicePropertiesContainer extends PureComponent {

    render() {
        const { device } = this.props;

        const rows = device ? this.renderRows(deviceRowDescriptors, device) : null;
        const vcmRows = device && device.vcm ? this.renderRows(deviceVcmRowDescriptors, device.vcm) : null;
        return (
            <div className='col-md-12'>
                <div className='bg-white padding-left-20 padding-right-20 padding-top-20 padding-bottom-20'>
                    <table className='table table-layout-fixed table-column-overflow-hidden table-head-filled table-sticky'>
                        <thead>
                            <tr>
                                <th className='col-md-3'><strong><FormattedMessage id='intl-msg:field' /></strong></th>
                                <th><strong><FormattedMessage id='intl-msg:value' /></strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                            {vcmRows}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    componentWillMount() {
        const { serialNumber } = this.props;
        this.props.fetchPropertiesForDevice(serialNumber);
    }

    componentWillUnmount() {
    }

    renderRows = (rowDescriptors, data) => {
        return map(rowDescriptors, (rowDescriptor) =>
            this.renderRow(rowDescriptor, data)
        );
    }

    renderRow = (rowDescriptor, data) => {
        if (!rowDescriptor.header) {
            const value = rowDescriptor.value ? rowDescriptor.value(data) : data[rowDescriptor.field];
            const label = <FormattedMessage id={`intl-msg:${rowDescriptor.field}`} />;

            const valueTd = (<td>{rowDescriptor.format ? rowDescriptor.format(value, data) : value}</td>);

            return <tr data-field={rowDescriptor.field} key={rowDescriptor.field}>
                <td>{rowDescriptor.formatLabel ? rowDescriptor.formatLabel(label) : label}</td>
                {valueTd}
            </tr>;
        }
        return <tr>
            <td>{rowDescriptor.header}</td><td></td>
        </tr>;
    }

}

export const mapStateToProps = (state, ownProps) => {
    return {
        device: getDevicePropertiesSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchPropertiesForDevice: (serialNumber) => {
            dispatch(fetchPropertiesForDevice(serialNumber));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicePropertiesContainer);

DevicePropertiesContainer.defaultProps = {
    // props
    serialNumber: '',
    device: {},
};

DevicePropertiesContainer.propTypes = {
    // props
    serialNumber: PropTypes.string,
    device: PropTypes.object,
    // functions
};
