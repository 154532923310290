import moment from 'moment';
import {
    FETCH_SIGNING_DEVICES,
    SUCCESSFUL_FETCH_SIGNING_DEVICES,
    FAILED_FETCH_SIGNING_DEVICES,
    RANGE_CHANGE_SIGNING_DEVICES,
    SigningState,
} from '../actions/devicesSigningActions';
import { DeviceType } from '../constants/deviceConstants';

const DEFAULT_STATE = {
    loading: false,
    signingDevices: [],
    startDateTime: moment().startOf('day').subtract(1, 'days'),
    endDateTime: moment().endOf('day'),
    signingState: SigningState.REJECTED,
    showDevicetype: DeviceType.TBM3,
    isLastPage: true,
    currentPage: 0,
    amountOfDevices: 0,
};

const setLoading = (state, loading) => {
    return {
        ...state,
        loading,
    };
};

const successfulFetchSiningDevices = (state, action) => {
    const { content, number, totalPages, totalElements, last, pageable } = action.payload;
    const oldDevices = number > 0 ? state.signingDevices : [];
    if (content) {
        content.forEach(element => {
            if (element.sreData) {
                element.imei = element.sreData.tbm3_imei;
                element.imsi = element.sreData.imsi;
                element.iccid = element.sreData.iccid;
            } else if (element.certData) {
                element.imei = element.certData.imei;
                element.imsi = element.certData.imsi;
                element.iccid = element.certData.iccid;
            }
            element.errorStatus = element.signingState.errorStatus;
            element.signingStatus = element.signingState.signingStatus;
            if (action.filterAction.signingState === SigningState.REJECTED) {
                element.requestCnt = element.cntRejectedSign;
            } else {
                element.requestCnt = element.cntFetchedSign;
            }
        });
    }
    return {
        ...state,
        loading: false,
        signingDevices: oldDevices.concat(content),
        amountOfDevices: totalElements,
        currentPage: number,
        totalPages,
        isLastPage: last,
        totalElements, pageable,
        signingState: action.filterAction.signingState,
        showDevicetype: action.filterAction.devicetype,
        startTimestamp: action.filterAction.startTimestamp,
        endTimestamp: action.filterAction.endTimestamp,
    };
};

const onRangeChange = (state, action) => {
    return {
        ...state,
        startDateTime: action.startValue,
        endDateTime: action.endValue,
    };
};

export function devicesSigningReducer(state = DEFAULT_STATE, action = {}) {
    switch (action.type) {
        case FETCH_SIGNING_DEVICES:
            return setLoading(state, true);
        case FAILED_FETCH_SIGNING_DEVICES:
            return setLoading(state, false);
        case SUCCESSFUL_FETCH_SIGNING_DEVICES:
            return successfulFetchSiningDevices(state, action);
        case RANGE_CHANGE_SIGNING_DEVICES:
            return onRangeChange(state, action);
        default:
            return state;
    }
}

export default devicesSigningReducer;
