import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import cloneDeep from 'lodash/cloneDeep'

import { RioBootstrapTable, RioTableHeaderColumn } from 'rio-uikit-legacy-table';

class SystemStateTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openRows: [],
            openedExpanders: [],
        };
    }

    openExpanderRow = (entityId) => {
        if (this.state.openRows.indexOf(entityId) === -1) {
            const openRows = this.state.openRows.slice();
            openRows.push(entityId);

            this.setState({
                openRows: openRows,
            });

            setTimeout(() => {
                const openedExpanders = this.state.openedExpanders.slice();
                openedExpanders.push(entityId);
                this.setState({
                    openedExpanders: openedExpanders,
                });
            }, 200);

        } else {
            const openRows = this.state.openRows.slice();
            openRows.splice(this.state.openRows.indexOf(entityId), 1);

            this.setState({
                openRows: openRows,
            });

            setTimeout(() => {
                const openedExpanders = this.state.openedExpanders.slice();
                openedExpanders.splice(openedExpanders.indexOf(entityId), 1);
                this.setState({
                    openedExpanders: openedExpanders,
                });
            }, 200);
        }
    }

    formatExpander = (cell, row) => {
        if (Array.isArray(cell)) {
            const entityReference = row.id;

            const isOpen = this.state.openRows.indexOf(entityReference) !== -1;
            const wasOpenedExpander = this.state.openedExpanders.indexOf(entityReference) !== -1;

            const animate = isOpen && !wasOpenedExpander || !isOpen && wasOpenedExpander;

            return (
                <div className={`btn btn-muted formatExpander ${animate ? 'animate' : ''} ${isOpen ? 'open' : ''}`}
                     onClick={(event) => {
                         event.preventDefault();
                         this.openExpanderRow(entityReference);
                     }}>
                    <span className='rioglyph rioglyph-chevron-down'></span>
                </div>
            );
        }

        return '';
    }

    getTrClassName = (row) => {
        return Array.isArray(row.globalIndex) ? 'compactRow' : 'extendedRow';
    }

    getRenderingData = () => {
        let realData = cloneDeep(this.compactData);

        this.state.openRows.forEach((entityId) => {
            const dataIndex = realData.findIndex(item => item.id === entityId);

            if (dataIndex !== -1) {
                const removedItems = realData.splice(dataIndex + 1, realData.length - dataIndex);

                if (this.extendedData[entityId]) {
                    realData = realData.concat(this.extendedData[entityId], removedItems);
                } else {
                    realData = realData.concat({ globalIndex: 'loading' }, removedItems);
                }
            }
        });

        return realData;
    }

    shouldHide = (isshown) => {
        return !(typeof isshown === 'boolean' && isshown === true);
    }

    render() {
        const { intl, columnsShown } = this.props;
        this.compactData = this.props.filterResults;
        this.extendedData = {};

        if (this.compactData) {
            let globalIndex = 0;
            for (let i = 0; i < this.compactData.length; i++) {
                //this.compactData[i].orig = {...this.compactData[i]};
                this.compactData[i].type = intl.formatMessage({ id: `intl-msg:${this.compactData[i].messageTypeEnum}` });
                this.compactData[i].time = intl.formatDate(new Date(this.compactData[i].timestamp), {
                    year: 'numeric', month: 'numeric', day: 'numeric',
                    hour: 'numeric', minute: 'numeric', second: 'numeric',
                });
                const maxList = Math.max(this.compactData[i].topCpuProcesses.length, this.compactData[i].topMemProcesses.length);
                if (this.compactData[i].restartedProcesses.length > 0) {
                    let restartedProcesses_list = [];
                    this.compactData[i].restartedProcesses.forEach(p => restartedProcesses_list.push(p.name))
                    this.compactData[i].restartedProcesses_list = restartedProcesses_list.join(', ');
                }
                if (this.compactData[i].topCpuProcesses.length > 0) {
                    this.compactData[i].topCpuProcess = this.compactData[i].topCpuProcesses[0].name;
                    this.compactData[i].topCpuProcess_cpu_usage = this.compactData[i].topCpuProcesses[0].cpu_usage;
                    this.compactData[i].topCpuProcess_average_cpu = this.compactData[i].topCpuProcesses[0].average_cpu;
                }
                if (this.compactData[i].topMemProcesses.length > 0) {
                    this.compactData[i].topMemProcess = this.compactData[i].topMemProcesses[0].name;
                    this.compactData[i].topMemProcess_mem_usage = this.compactData[i].topMemProcesses[0].mem_usage;
                }
                for (let j=0; j < this.compactData[i].processLogLevel.length; j++) {
                    let sep = j > 0 ? ', ' : '';
                    let processLogLevel =  this.compactData[i].processLogLevel[j];
                    this.compactData[i].processLogLevels = sep + processLogLevel.name + " (" + processLogLevel.logLevel + ")";
                }
                if (this.compactData[i].emmcHealthInfo) {
                    this.compactData[i].emmcHealthInfo_user = this.compactData[i].emmcHealthInfo.user;
                    this.compactData[i].emmcHealthInfo_system = this.compactData[i].emmcHealthInfo.system;
                }
                let extRows = [];
                let extIndex = [];
                for (let j=1; j < maxList; j++, globalIndex++) {
                    let row = {globalIndex: globalIndex};
                    extIndex.push(globalIndex);
                    if (this.compactData[i].topCpuProcesses.length >= j) {
                        row.topCpuProcess = this.compactData[i].topCpuProcesses[j].name;
                        row.topCpuProcess_cpu_usage = this.compactData[i].topCpuProcesses[j].cpu_usage;
                        row.topCpuProcess_average_cpu = this.compactData[i].topCpuProcesses[j].average_cpu;
                    }
                    if (this.compactData[i].topMemProcesses.length >= j) {
                        row.topMemProcess = this.compactData[i].topMemProcesses[j].name;
                        row.topMemProcess_mem_usage = this.compactData[i].topMemProcesses[j].mem_usage;
                    }    
                    extRows.push(row);                    
                }
                this.extendedData[this.compactData[i].id] = extRows;
                this.compactData[i].globalIndex = extIndex;
            }
        }

        return (
            <RioBootstrapTable data={this.getRenderingData()} keyField='globalIndex' 
                enableRowSelection={ false }
                trClassName={this.getTrClassName}>
              <RioTableHeaderColumn dataField='type' columnTitle width='100'>{'TYPE'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='time' columnTitle width='150'>{intl.formatMessage({ id: 'intl-msg:notificationTime' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='average_cpu' headerText={intl.formatMessage({ id: 'intl-msg:avgCpuTooltip' })} width='80'>{'AVG CPU (%)'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='cpu_usage' width='80'>{'CPU (%)'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='cpu0_usage' hidden={this.shouldHide(columnsShown.cpu0_usage)} width='80'>{'CPU0 (%)'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='cpu1_usage' hidden={this.shouldHide(columnsShown.cpu1_usage)} width='80'>{'CPU1 (%)'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='loadavg_1' hidden={this.shouldHide(columnsShown.loadavg_1)} headerText='1 min Linux load average' width='100'>{'LOAD AVG 1'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='loadavg_5' hidden={this.shouldHide(columnsShown.loadavg_5)} headerText='5 min Linux load average' width='100'>{'LOAD AVG 5'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='loadavg_15' hidden={this.shouldHide(columnsShown.loadavg_15)} headerText='15 min Linux load average' width='100'>{'LOAD AVG 15'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='mem_usage' hidden={this.shouldHide(columnsShown.mem_usage)} width='120'>{'MEM USAGE (kB)'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='mem_free' hidden={this.shouldHide(columnsShown.mem_free)} width='120'>{'FREE MEM (kB)'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='topCpuProcess' columnTitle headerText='TOP CPU PROCESS' width='120'>{'TOP CPU'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='topCpuProcess_cpu_usage' headerText='TOP PROCESS CPU (%)' width='80'>{'TOP CPU (%)'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='topCpuProcess_average_cpu' headerText={intl.formatMessage({ id: 'intl-msg:avgCpuTooltip' })} width='80'>{'TOP AVG CPU (%)'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='topMemProcess' columnTitle headerText='TOP MEM PROCESS' width='150'>{'TOP MEM'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='topMemProcess_mem_usage' headerText='TOP PROCESS MEM (kB)' width='120'>{'TOP MEM (kB)'}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='processLogLevels' hidden={this.shouldHide(columnsShown.processLogLevels)} columnTitle headerText={intl.formatMessage({ id: 'intl-msg:processLogLevels' })} width='150'>{intl.formatMessage({ id: 'intl-msg:processLogLevels' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='restartedProcesses_list' hidden={this.shouldHide(columnsShown.restartedProcesses_list)} columnTitle headerText={intl.formatMessage({ id: 'intl-msg:restartedProcesses' })} width='100'>{intl.formatMessage({ id: 'intl-msg:restarted' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='processesNotRunning' hidden={this.shouldHide(columnsShown.processesNotRunning)} columnTitle headerText={intl.formatMessage({ id: 'intl-msg:notRunningProcesses' })} width='150'>{intl.formatMessage({ id: 'intl-msg:notRunning' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='system_state_version' hidden={this.shouldHide(columnsShown.system_state_version)} columnTitle headerText={intl.formatMessage({ id: 'intl-msg:system_state_version' })} width='80'>{intl.formatMessage({ id: 'intl-msg:version' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='base_sw_version' hidden={this.shouldHide(columnsShown.base_sw_version)} columnTitle headerText={intl.formatMessage({ id: 'intl-msg:base_sw_version' })} width='110'>{intl.formatMessage({ id: 'intl-msg:base_sw_version' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='emmcHealthInfo_user' hidden={this.shouldHide(columnsShown.emmcHealthInfo_user)} columnTitle headerText={intl.formatMessage({ id: 'intl-msg:emmcHealthInfo_user' })} width='80'>{intl.formatMessage({ id: 'intl-msg:emmcHealthInfo_user' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='emmcHealthInfo_system' hidden={this.shouldHide(columnsShown.emmcHealthInfo_system)} columnTitle headerText={intl.formatMessage({ id: 'intl-msg:emmcHealthInfo_system' })} width='80'>{intl.formatMessage({ id: 'intl-msg:emmcHealthInfo_system' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='disc_usage' hidden={this.shouldHide(columnsShown.disc_usage)} columnTitle headerText={intl.formatMessage({ id: 'intl-msg:disc_usage' })} width='80'>{intl.formatMessage({ id: 'intl-msg:disc_usage' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='disc_usage_common' hidden={this.shouldHide(columnsShown.disc_usage_common)} columnTitle headerText={intl.formatMessage({ id: 'intl-msg:disc_usage_common' })} width='80'>{intl.formatMessage({ id: 'intl-msg:disc_usage_common' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='disc_usage_handover' hidden={this.shouldHide(columnsShown.disc_usage_handover)} columnTitle headerText={intl.formatMessage({ id: 'intl-msg:disc_usage_handover' })} width='80'>{intl.formatMessage({ id: 'intl-msg:disc_usage_handover' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='boot_bank_id' hidden={this.shouldHide(columnsShown.boot_bank_id)} columnTitle headerText={intl.formatMessage({ id: 'intl-msg:boot_bank_id' })} width='80'>{intl.formatMessage({ id: 'intl-msg:boot_bank_id' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn dataField='boot_bank_switch_reason' hidden={this.shouldHide(columnsShown.boot_bank_switch_reason)} columnTitle headerText={intl.formatMessage({ id: 'intl-msg:boot_bank_switch_reason' })} width='80'>{intl.formatMessage({ id: 'intl-msg:boot_bank_switch_reason' })}</RioTableHeaderColumn>
              <RioTableHeaderColumn
                    dataField='globalIndex'
                    dataAlign='right'
                    width='80'
                    dataFormat={this.formatExpander}>
                    {''}
                </RioTableHeaderColumn>
            </RioBootstrapTable>
        );
        
    }
}

export default injectIntl(SystemStateTable);
