import moment from 'moment';
import {
    FETCH_DEVICE_STATE_NOTIFICATIONS,
    SUCCESSFUL_FETCH_DEVICE_STATE_NOTIFICATIONS,
    FAILED_FETCH_DEVICE_STATE_NOTIFICATIONS,
    FETCH_STATE_NOTIFICATIONS_OF_DEVICE,
    SUCCESSFUL_FETCH_STATE_NOTIFICATIONS_OF_DEVICE,
    FAILED_FETCH_STATE_NOTIFICATIONS_OF_DEVICE,
    RANGE_CHANGE_DEVICE_STATE_NOTIFICATIONS,
    SELECT_MESSAGE_TYPE_FILTER,
} from '../actions/systemStateActions';

const DEFAULT_STATE = {
    loading: false,
    deviceStateNotifications: [],
    stateNotificationsOfDevice: [],
    selectedMessageTypeFilter: ['CPU_LIMIT', 'MEM_LIMIT', 'APP_CRASH', 'PROC_NOT_RUNNING', 'AUTO_BANK_SWITCH'],
    startDateTime: moment().startOf("day").subtract(1, 'days'),
    endDateTime: moment().endOf("day"),
    isLastPage: true,
    currentPage: 0,
    amountOfDevices: 0,
    };

const setLoading = (state, loading) => {
    return {
        ...state,
        loading,
    };
};

const cleanAvgCpu = (element => {
    if (element.average_cpu < 0) {
        element.average_cpu = '';
    }
});

const successfulFetchDeviceStateNotifications = (state, action) => {
    const { content, number, totalPages, totalElements, last, pageable } = action.payload;
    const oldDevices = number > 0 ? state.deviceStateNotifications.slice() : [];
    content.forEach(cleanAvgCpu);
    return {
        ...state,
        loading: false,
        deviceStateNotifications: oldDevices.concat(content),
        amountOfDevices: totalElements,
        currentPage: number,
        totalPages,
        isLastPage: last,
        totalElements, pageable,
    };
};

const successfulFetchStateNotificationsOfDevice = (state, action) => {
    action.payload.content.forEach(element => {
        cleanAvgCpu(element);
        element.topCpuProcesses.forEach(cleanAvgCpu);
    });
    const { base_sw_version, system_state_version } = action.payload.content.length > 0 ? action.payload.content[0] : {};
    return {
        ...state,
        loading: false,
        stateNotificationsOfDevice: action.payload.content,
        systemStateVersion: system_state_version,
        baseSwVersion: base_sw_version,
    };
};

const onRangeChange = (state, action) => {
    return {
        ...state,
        startDateTime: action.startValue, 
        endDateTime: action.endValue,
    };
};

const onSelectMessageTypeFilter = (state, action) => {
    return {
        ...state,
        selectedMessageTypeFilter: action.newSelectedMessageFilter.slice(), 
    };
};

export function systemStateReducer(state = DEFAULT_STATE, action = {}) {
    switch (action.type) {
        case FETCH_DEVICE_STATE_NOTIFICATIONS:
        case FETCH_STATE_NOTIFICATIONS_OF_DEVICE:
            return setLoading(state, true);
        case FAILED_FETCH_DEVICE_STATE_NOTIFICATIONS:
        case FAILED_FETCH_STATE_NOTIFICATIONS_OF_DEVICE:
            return setLoading(state, false);
        case SUCCESSFUL_FETCH_DEVICE_STATE_NOTIFICATIONS:
            return successfulFetchDeviceStateNotifications(state, action);
        case SUCCESSFUL_FETCH_STATE_NOTIFICATIONS_OF_DEVICE:
            return successfulFetchStateNotificationsOfDevice(state, action);
        case RANGE_CHANGE_DEVICE_STATE_NOTIFICATIONS:
            return onRangeChange(state, action);
        case SELECT_MESSAGE_TYPE_FILTER:
            return onSelectMessageTypeFilter(state, action);
        default:
            return state;
    }
}

export default systemStateReducer;
