import {
  SEARCH_DEVICES, FAILED_SEARCH_DEVICES, FETCH_FILTERED_DEVICES,
  SUCCESSFUL_FETCH_FILTERED_DEVICES, SUCCESSFUL_CSV_EXPORT_DEVICES, CSV_EXPORT_DEVICES, FAILED_FETCH_FILTERED_DEVICES,
} from './../actions/showResultActions';
import download from 'downloadjs';

const DEFAULT_STATE = {
  devices: [],
  successDevices: 0,
  noSuccessDevices: 0,
  replacedDevices: 0,
  lostDevices: 0,
  paging: {
    total: 0,
    page: 0,
    pages: 0,
  },
  loading: false,
};

const setLoading = (state, loading) => {
  return {
    ...state,
    loading,
  };
};

const successfulFetchFilteredDevices = (state, action) => {
  const page = action.payload.number;
  const devices = action.payload.content;
  const oldDevices = page > 0 ? state.devices : [];
  return {
    ...state,
    ...action.payload.devicesInfo,
    paging: {
      total: action.payload.totalElements,
      page: page,
      pages: action.payload.totalPages,
    },
    devices: oldDevices.concat(devices),
    loading: false,
  };
};

const successfulExportFilteredDevices = (state, action) => {
  download(action.exportDevicesData, action.filename, 'text/csv');
  return {
    ...state,
    loading: false,
  };
};

export function showResultReducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case SEARCH_DEVICES:
    case CSV_EXPORT_DEVICES:
    case FETCH_FILTERED_DEVICES:
      return setLoading(state, true);
    case FAILED_SEARCH_DEVICES:
    case FAILED_FETCH_FILTERED_DEVICES:
      return setLoading(state, false);
    case SUCCESSFUL_FETCH_FILTERED_DEVICES:
      return successfulFetchFilteredDevices(state, action);
    case SUCCESSFUL_CSV_EXPORT_DEVICES:
      return successfulExportFilteredDevices(state, action);
    default:
      return state;
  }
}

export default showResultReducer;
