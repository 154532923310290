import { call, put, select, fork, takeLatest } from 'redux-saga/effects';

import {
    CHECK_DEVICES_AND_ADD_RESULTS_TO_DB, successfulCheckDevicesAndAddResultsToDb, failedCheckDevicesAndAddResultsToDb,
    CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB, successUploadSREDataToDb, failedUploadSREDataToDb,
} from './../actions/uploadActions';

import { showErrorMessage } from './../actions/ui/notificationsActions';
import { getDeviceMonitoringBackend, getDeviceBackend } from './../selectors/backendSelectors';

import { doHandleErrorSaga, requestText, requestJSON } from './sagaUtil';

export function* getServiceURL() {
    const serviceURL = yield select(getDeviceMonitoringBackend);
    return `${serviceURL}`;
}
export function* getDeviceServiceURL() {
    const serviceURL = yield select(getDeviceBackend);
    return `${serviceURL}`;
}

export function* doCheckDevicesAndAddResultsToDb(action) {
    try {
        const url = yield call(getServiceURL);
        // Can't use putHTTP because it tries to parse response as JSON
        yield call(requestText, `${url}/devices/success-init`, {
            method: 'PUT',
        });
        // Can't use postHTTP because it tries to parse response as JSON
        const response = yield call(requestText, `${url}/devices/upload`, {
            method: 'POST',
            body: JSON.stringify(action.newTBM3s),
        });
        yield put(successfulCheckDevicesAndAddResultsToDb(response));
    } catch (error) {
        yield fork(doHandleErrorSaga, error);
        yield put(failedCheckDevicesAndAddResultsToDb(error));
        yield put(showErrorMessage(error));
    }
}

export function* checkDevicesAndAddResultsToDbSaga() {
    yield takeLatest(CHECK_DEVICES_AND_ADD_RESULTS_TO_DB, doCheckDevicesAndAddResultsToDb);
}

export function* doCheckSREDevicesAndAddResultsToDb(action) {
    try {
        const url = yield call(getDeviceServiceURL);
        let csv = '';
        action.newTBM3s.forEach((row) => {
            csv += row.join(';');
            csv += '\n';
        });

        const response = yield call(requestJSON, `${url}/uploadFileTBM3DeviceInformation?filename=${action.filename}`, {
            method: 'POST',
            body: csv,
            headers: {
                'Content-Type': 'text/csv',
                Accept: 'application/json',
            },
        });
        yield put(successUploadSREDataToDb(response));
    } catch (error) {
        yield fork(doHandleErrorSaga, error);
        yield put(failedUploadSREDataToDb(error.message));
        yield put(showErrorMessage(error));
    }
}

export function* checkSREDevicesAndAddResultsToDbSaga() {
    yield takeLatest(CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB, doCheckSREDevicesAndAddResultsToDb);
}
