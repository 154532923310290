// This is the runtime configuration being used for accessing
// production data from a local frontend build

export const localProdConfig = {
    id: 'env.localprod',
    backend: {
        AUTHENTICATION_SERVICE: 'https://login.microsoftonline.com/7e4c72b8-86d8-4e3a-90d8-4f7b7b2513c2/oauth2/v2.0',
        DEVICE_MONITORING_SERVICE: 'https://api.device-init.rio.cloud/api/deviceinitmonitor',
        SYSTEM_STATE_NOTIFICATIONS_SERVICE: 'https://system-state.device-ota-updates.rio.cloud/api/tbm3systemstatenotification',
        DEVICE_SERVICE: 'https://api.device-init.rio.cloud/api/deviceService',
        MESSAGESENDER_SERVICE: 'https://system-state.device-ota-updates.rio.cloud/api/tbm3systemstatenotification',
    },
    homeRoute: `${window.location.origin}`,
    appMenuUri: 'http://localhost:8040/#app-menu',
    userMenuUri: 'http://localhost:8040/#user-menu',
    login: {
        authority: 'https://login.microsoftonline.com/7e4c72b8-86d8-4e3a-90d8-4f7b7b2513c2',
        clientId: '395d6c94-7085-4068-9cfa-021cfe60b30d',
        resource: '395d6c94-7085-4068-9cfa-021cfe60b30d',
        mockAuthorization: true,
        preventRedirect: false,
        redirectUri: 'https://monitor-eu.device-init.rio.cloud/redirect.html',
        silentRedirectUri: 'https://monitor-eu.device-init.rio.cloud/redirect.html',
    },
    logoutUri: `https://login.microsoftonline.com/7e4c72b8-86d8-4e3a-90d8-4f7b7b2513c2/oauth2/v2.0/logout?redirect_uri=https://admin-eu.rio.cloud`,
    features: {
        nasysAvailable: true,
        vcmAvailable: true,
        certificatesAvailable: true,
    },
};

