/* eslint-disable no-undef */
import * as Sentry from '@sentry/browser';
import { env } from '~/env';

// should have been called before using it here
// ideally before even rendering your react app
if (env.shouldSendMetrics) {
    Sentry.init({
        dsn: env.runtimeConfig.sentryToken,
    });
}

export const reportErrorToSentry = (...args) => {
    Sentry.captureException(...args);
};
