import compact from 'lodash/fp/compact';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { ApplicationLayout, ApplicationHeader, ActionBarItem, NotificationsContainer } from 'rio-uikit';

import { SessionExpiredDialog } from 'rio-session-expired-info';

import IframeResizer from 'iframe-resizer-react';

import { env } from '~/env';
const { features } = env.runtimeConfig;

const navItems = compact([{
    key: 'systemstate',
    route: (
        <NavLink to={`/systemstate`}><FormattedMessage id='intl-msg:devicestates'/></NavLink>
    ),
}, {
    key: 'devicesSigning',
    route: (
        <NavLink to={`/devicesSigning`}><FormattedMessage id='intl-msg:signing'/></NavLink>
    ),
}, features.nasysAvailable ? {
    key: 'nasysJournal',
    route: (
        <NavLink to={`/journal`}><FormattedMessage id='intl-msg:journal'/></NavLink>
    ),
} : null, features.nasysAvailable ? {
    key: 'showresult',
    route: (
        <NavLink to={`/showresult/0`}>{'NASYS results'}</NavLink>
    ),
} : null, features.nasysAvailable ? {
    key: 'upload',
    route: (
        <NavLink to={`/upload`}>{'upload'}</NavLink>
    ),
} : null, features.certificatesAvailable ? {
    key: 'certificate',
    route: (
        <NavLink to={`/certificate`}><FormattedMessage id='intl-msg:certificates'/></NavLink>
    ),
} : null
]);

export const ApplicationFrame = (props) => {
    const {
        homeRoute,
        appMenuUri,
        userMenuUri,
        userLocale,
        showSessionExpired,
        children,
        onHideSessionDialog,
    } = props;

    const appMenu = (
        <IframeResizer
            src={appMenuUri}
            checkOrigin={false}
            style={{ width: '1px', minWidth: '100%', height: '85px', border: '0' }}/>
    );

    const actionBarItems = [
        <ActionBarItem id='userMenu'>
            <ActionBarItem.Icon>
                <span className='icon rioglyph rioglyph-user'></span>
            </ActionBarItem.Icon>
            <ActionBarItem.Popover useOffscreen>
                <IframeResizer
                    id={'popover-iframe'}
                    src={userMenuUri}
                    checkOrigin={false}
                    style={{ width: '1px', minWidth: '100%', height: '91px', border: '0' }}
                />
            </ActionBarItem.Popover>
        </ActionBarItem>,
    ];
    return (
        <ApplicationLayout>
            <ApplicationLayout.Header>
                <ApplicationHeader
                    homeRoute={<a href={homeRoute}/>}
                    label='RIO Box Monitor'
                    appNavigator={appMenu}
                    navItems={navItems}
                    actionBarItems={actionBarItems}/>
            </ApplicationLayout.Header>
            <ApplicationLayout.Body className='responsive'>
                <NotificationsContainer/>
                <SessionExpiredDialog
                    locale={userLocale}
                    onClose={onHideSessionDialog}
                    show={showSessionExpired}/>
                {children}
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
};

export default ApplicationFrame;

ApplicationFrame.propTypes = {
    // props
    homeRoute: PropTypes.string,
    appMenuUri: PropTypes.string,
    userMenuUri: PropTypes.string,
    userLocale: PropTypes.string,
    showSessionExpired: PropTypes.bool,
    // functions
    onHideSessionDialog: PropTypes.func,
};
