import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Checkbox, SimpleButtonDropdown, SpinnerInfoBox} from 'rio-uikit';

import {
  csvExportSystemState,
  fetchStateNotificationsOfDevice,
  onRangeChange,
  onSelectMessageTypeFilter,
  requestStateNotificationFromDevice,
} from '../actions/systemStateActions';
import {
  getBaseSwVersion,
  getEndDateTime,
  getLoadingOfDeviceInfo,
  getSelectedMessageTypeFilter,
  getStartDateTime,
  getStateNotificationsOfDevice,
  getSystemStateVersion,
} from '../selectors/systemStateSelectors';

import SystemStateFilter from './SystemStateFilter';
import SystemStateTable from './SystemStateTable';

class DeviceSystemStatesPage extends Component {

  constructor(props) {
    super(props);
    this.columnsShownKey = 'DEVICEMONITOR.columnsShown';
    let columnsShown;
    try {
      columnsShown = JSON.parse(localStorage.getItem(this.columnsShownKey));
    } catch (Error) {
      localStorage.removeItem(this.columnsShownKey)
      columnsShown = {};
    }

    this.state = {
      columnsShown: {
        cpu0_usage: this.props.selectedMessageTypeFilter.includes('CPU_LIMIT'),
        cpu1_usage: this.props.selectedMessageTypeFilter.includes('CPU_LIMIT'),
        loadavg_1: this.props.selectedMessageTypeFilter.includes('CPU_LIMIT'),
        loadavg_5: this.props.selectedMessageTypeFilter.includes('CPU_LIMIT'),
        loadavg_15: this.props.selectedMessageTypeFilter.includes('CPU_LIMIT'),
        mem_usage: this.props.selectedMessageTypeFilter.includes('MEM_LIMIT'),
        mem_free: this.props.selectedMessageTypeFilter.includes('MEM_LIMIT'),
        restartedProcesses_list: this.props.selectedMessageTypeFilter.includes(
            'APP_CRASH'),
        processesNotRunning: this.props.selectedMessageTypeFilter.includes(
            'APP_CRASH'),
        system_state_version: false,
        base_sw_version: false,
        processLogLevels: false,
        emmcHealthInfo_user: false,
        emmcHealthInfo_system: false,
        disc_usage: false,
        disc_usage_common: false,
        disc_usage_handover: false,
        boot_bank_id: false,
        boot_bank_switch_reason: false,
        ...columnsShown,
      },
    };
  }

  setShowColumnFlag = (flag) => {
    localStorage.setItem(this.columnsShownKey,
        JSON.stringify({...this.state.columnsShown, ...flag}));
    this.setState({columnsShown: {...this.state.columnsShown, ...flag}});
  }

  render() {
    const {intl} = this.props;

    const disabledColumns = [{
      value: 'Show/Hide Columns',
      header: true,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.cpu0_usage}
                    onClick={() => this.setShowColumnFlag({
                      cpu0_usage: this.state.columnsShown.cpu0_usage !== true
                    })}>
            {'CPU0 (%)'}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.cpu1_usage}
                    onClick={() => this.setShowColumnFlag({
                      cpu1_usage: this.state.columnsShown.cpu1_usage !== true
                    })}>
            {'CPU1 (%)'}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.loadavg_1}
                    onClick={() => this.setShowColumnFlag({
                      loadavg_1: this.state.columnsShown.loadavg_1 !== true
                    })}>
            {'LOAD AVG 1'}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.loadavg_5}
                    onClick={() => this.setShowColumnFlag({
                      loadavg_5: this.state.columnsShown.loadavg_5 !== true
                    })}>
            {'LOAD AVG 5'}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.loadavg_15}
                    onClick={() => this.setShowColumnFlag({
                      loadavg_15: this.state.columnsShown.loadavg_15 !== true
                    })}>
            {'LOAD AVG 15'}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.mem_usage}
                    onClick={() => this.setShowColumnFlag({
                      mem_usage: this.state.columnsShown.mem_usage !== true
                    })}>
            {'MEM USAGE (kB)'}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.mem_free}
                    onClick={() => this.setShowColumnFlag(
                        {mem_free: this.state.columnsShown.mem_free !== true})}>
            {'FREE MEM (kB)'}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.processLogLevels}
                    onClick={() => this.setShowColumnFlag({
                      processLogLevels: this.state.columnsShown.processLogLevels
                          !== true
                    })}>
            {intl.formatMessage({id: 'intl-msg:processLogLevels'})}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.restartedProcesses_list}
                    onClick={() => this.setShowColumnFlag({
                      restartedProcesses_list: this.state.columnsShown.restartedProcesses_list
                          !== true
                    })}>
            {intl.formatMessage({id: 'intl-msg:restartedProcesses'})}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.processesNotRunning}
                    onClick={() => this.setShowColumnFlag({
                      processesNotRunning: this.state.columnsShown.processesNotRunning
                          !== true
                    })}>
            {intl.formatMessage({id: 'intl-msg:notRunningProcesses'})}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.system_state_version}
                    onClick={() => this.setShowColumnFlag({
                      system_state_version: this.state.columnsShown.system_state_version
                          !== true
                    })}>
            {intl.formatMessage({id: 'intl-msg:system_state_version'})}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.base_sw_version}
                    onClick={() => this.setShowColumnFlag({
                      base_sw_version: this.state.columnsShown.base_sw_version
                          !== true
                    })}>
            {intl.formatMessage({id: 'intl-msg:base_sw_version'})}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.emmcHealthInfo_user}
                    onClick={() => this.setShowColumnFlag({
                      emmcHealthInfo_user: this.state.columnsShown.emmcHealthInfo_user
                          !== true
                    })}>
            {intl.formatMessage({id: 'intl-msg:emmcHealthInfo_user'})}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.emmcHealthInfo_system}
                    onClick={() => this.setShowColumnFlag({
                      emmcHealthInfo_system: this.state.columnsShown.emmcHealthInfo_system
                          !== true
                    })}>
            {intl.formatMessage({id: 'intl-msg:emmcHealthInfo_system'})}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.disc_usage}
                    onClick={() => this.setShowColumnFlag({
                      disc_usage: this.state.columnsShown.disc_usage !== true
                    })}>
            {intl.formatMessage({id: 'intl-msg:disc_usage'})}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.disc_usage_common}
                    onClick={() => this.setShowColumnFlag({
                      disc_usage_common: this.state.columnsShown.disc_usage_common
                          !== true
                    })}>
            {intl.formatMessage({id: 'intl-msg:disc_usage_common'})}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.disc_usage_handover}
                    onClick={() => this.setShowColumnFlag({
                      disc_usage_handover: this.state.columnsShown.disc_usage_handover
                          !== true
                    })}>
            {intl.formatMessage({id: 'intl-msg:disc_usage_handover'})}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.boot_bank_id}
                    onClick={() => this.setShowColumnFlag({
                      boot_bank_id: this.state.columnsShown.boot_bank_id
                          !== true
                    })}>
            {intl.formatMessage({id: 'intl-msg:boot_bank_id'})}
          </Checkbox>,
    }, {
      value:
          <Checkbox checked={this.state.columnsShown.boot_bank_switch_reason}
                    onClick={() => this.setShowColumnFlag({
                      boot_bank_switch_reason: this.state.columnsShown.boot_bank_switch_reason
                          !== true
                    })}>
            {intl.formatMessage({id: 'intl-msg:boot_bank_switch_reason'})}
          </Checkbox>,
    }];

    return (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <Link to={'/systemstate'}>
                <span className='rioglyph rioglyph-chevron-left'
                      aria-hidden='true'></span>
                <FormattedMessage id='intl-msg:backToDevices'/>
              </Link>
              <h1><FormattedMessage id='intl-msg:devicestates'/></h1>
              <br/>
              <h2><FormattedMessage id='intl-msg:deviceSystemStateNotifications'
                                    values={{serialNumber: this.props.serialNumber}}/>
              </h2>
              <h6>{`Base SW: ${this.props.baseSwVersion} System State: ${this.props.systemStateVersion}`}</h6>
              <br/>
              <div className='row'>
                <div className='col-md-11'>
                  <SystemStateFilter
                      selectedMessageTypeFilter={this.props.selectedMessageTypeFilter}
                      onSelectMessageTypeFilter={this.props.onSelectMessageTypeFilter}
                      onTimeRangeChange={this.props.onRangeChange}
                      startValue={this.props.startDateTime}
                      endValue={this.props.endDateTime}
                      csvExportSystemState={this.csvExportSystemState}
                      fetchSystemStateNotifications={this.fetchStateNotificationsOfDevice}
                      requestStateNotificationFromDevice={this.requestStateNotificationFromDevice}
                      searchButtonText={<FormattedMessage
                          id='intl-msg:reload'/>}
                  />
                </div>
                <div className='col-md-1'>
                  <div className='text-right'>
                    <SimpleButtonDropdown items={disabledColumns}
                                          title={<span
                                              className='rioglyph rioglyph-cog'
                                              aria-hidden='true'/>} iconOnly/>
                  </div>
                </div>
              </div>
              {
                this.props.loading &&
                <div>
                  <SpinnerInfoBox text={'Loading'} isInverse={false}/>
                </div>
              }
              <div>
                <SystemStateTable
                    filterResults={this.props.stateNotificationsOfDevice}
                    columnsShown={this.state.columnsShown}/>
              </div>
            </div>
          </div>
        </div>
    );
  }

  componentDidMount() {
    this.fetchStateNotificationsOfDevice();
  }

  fetchStateNotificationsOfDevice = () => {
    const startDateTime = this.props.startDateTime.valueOf();
    const endDateTime = this.props.endDateTime.valueOf();
    this.props.fetchStateNotificationsOfDevice(this.props.serialNumber,
        this.props.selectedMessageTypeFilter,
        startDateTime, endDateTime);
  }
  csvExportSystemState = () => {
    const startDateTime = this.props.startDateTime.valueOf();
    const endDateTime = this.props.endDateTime.valueOf();
    this.props.csvExportSystemState(this.props.serialNumber,
        this.props.selectedMessageTypeFilter,
        startDateTime, endDateTime);
  }
  requestStateNotificationFromDevice = () => {
    this.props.requestStateNotificationFromDevice(this.props.serialNumber);
  }
}

export const mapStateToProps = (state) => ({
  loading: getLoadingOfDeviceInfo(state),
  stateNotificationsOfDevice: getStateNotificationsOfDevice(state),
  selectedMessageTypeFilter: getSelectedMessageTypeFilter(state),
  startDateTime: getStartDateTime(state),
  endDateTime: getEndDateTime(state),
  baseSwVersion: getBaseSwVersion(state),
  systemStateVersion: getSystemStateVersion(state),
});

export const mapDispatchToProps = (dispatch) => ({
  fetchStateNotificationsOfDevice: (serialNumber, selectedMessageTypeFilter,
      startTimestamp, endTimestamp) => {
    dispatch(
        fetchStateNotificationsOfDevice(serialNumber, selectedMessageTypeFilter,
            startTimestamp, endTimestamp));
  },
  csvExportSystemState: (serialNumber, selectedMessageTypeFilter,
      startTimestamp, endTimestamp) => {
    dispatch(csvExportSystemState(serialNumber, selectedMessageTypeFilter,
        startTimestamp, endTimestamp));
  },
  requestStateNotificationFromDevice: (serialNumber) => {
    dispatch(requestStateNotificationFromDevice(serialNumber));
  },
  onSelectMessageTypeFilter: (newSelectedMessageFilter) => {
    dispatch(onSelectMessageTypeFilter(newSelectedMessageFilter));
  },
  onRangeChange: (startValue, endValue) => {
    dispatch(onRangeChange(startValue, endValue));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(DeviceSystemStatesPage));
