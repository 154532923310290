export const FETCH_JOURNAL_RECORDS = 'FETCH_JOURNAL_RECORDS';
export const SUCCESSFUL_FETCH_JOURNAL_RECORDS = 'SUCCESSFUL_FETCH_JOURNAL_RECORDS';

export const fetchJournalRecords = (pageToShow) => {
    return {
        type: FETCH_JOURNAL_RECORDS,
        pageToShow,
    };
};

export const successfulFetchJournalRecords = (response) => {
    return {
        type: SUCCESSFUL_FETCH_JOURNAL_RECORDS,
        payload: response,
    };
};
