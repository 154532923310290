import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import merge from 'lodash/fp/merge';

import { localAuthConfig } from './env/env.localauth';
import { localConfig } from './env/env.local';
import { localProdConfig } from './env/env.localprod';
import { productionEUConfig } from './env/env.production.eu';
import { productionLATAMConfig } from './env/env.production.latam';
import { testConfig } from './env/env.test';

export const getConfig = get('config');

export const getLoginClientId = get('config.login.clientId');

export const getLoginRedirectUri = get('config.login.redirectUri');

export const getProdEnvironment = (host) => {
    if (includes('-latam', host)) {
        return productionLATAMConfig;
    }
    return productionEUConfig;
};
export const isProdEnvironment = (host) => {
    return includes('device-init', host);
};
export const determineFeatureToggles = (window) => {
    /* eslint-disable immutable/no-let */

    const param = (regex, defaultValue = null) => {
        let result = defaultValue;
        decodeURI(window.location.href).replace(regex, (_, it) => {
            result = it;
        });
        return result;
    };

    const detectToggle = (transform, name, defaultValue = null) => {
        // Note that IE11 throws a SyntaxError when using 'u' here
        // eslint-disable-next-line require-unicode-regexp
        let value = param(new RegExp(`${name}=([^&]+)`));

        try {
            if (value) {
                window.localStorage.setItem(name, value);
            } else {
                value = window.localStorage.getItem(name);
            }
        } catch (_) {
            // Intentionally left blank
        }
        return value ? transform(value) : defaultValue;
    };

    const BooleanToggle = (value) => {
        if (value === 'true' || value === '1') {
            return true;
        }
        return false;
    };

    // ... add your custom feature toggles here
    return {
        enforcedEnv: detectToggle(String, 'ft_env'),
        enforcedLocale: detectToggle(String, 'ft_enforceLocale'),
        mockedToken: detectToggle(String, 'ft_mockToken'),
        tracing: detectToggle(BooleanToggle, 'ft_tracing'),
    };
};

export const getRuntimeConfig = (window, env = {}) => {
    const host = window.location.host;
    const defaultConfig = {
        backend: {},
        homeRoute: `${window.location.origin}`,
        id: 'env.stub',
        login: {
            // TODO: Request and supply your App's `client_id` as
            //       well as the needed OAuth scopes here
            clientId: '395d6c94-7085-4068-9cfa-021cfe60b30d',
            oauthScope: [
                'openid',
                'profile',
                'email',
            ],
            mockLocale: 'en-GB',
            preventRedirect: false,
        },
        // TODO: Create a project in Sentry and use the token here
        sentryToken: 'https://41d07c125c5f4abeb3eee4d75766ed95@sentry.io/1401796',
    };

    // eslint-disable-next-line immutable/no-let
    let config = localConfig;

    if (env.isRunningOnProd) {
        config = getProdEnvironment(host);
    }

    if (env.featureToggles.enforcedEnv === 'localauth') {
        config = localAuthConfig;
    }

    if (env.featureToggles.enforcedEnv === 'localprod') {
        config = localProdConfig;
    }

    if (env.featureToggles.enforcedEnv === 'production') {
        config = getProdEnvironment(host);
    }

    if (env.isTestEnv) {
        config = testConfig;
    }

    return merge(merge({}, defaultConfig), config);
};

export const configureEnv = (window, process) => {
    const { location: { host } } = window;
    const { env: { NODE_ENV } } = process;

    // TODO: Change the URLs to the ones your service is using
    const isProdEnv = isProdEnvironment(host);
    const isStagingEnv = isProdEnvironment(host);
    const isTestEnv = NODE_ENV === 'test';
    const isLocalEnv = !isProdEnv && !isStagingEnv && !isTestEnv;

    const shouldSendMetrics = isProdEnv || isStagingEnv;

    const isProdBuild = NODE_ENV === 'production';
    const isRunningOnProd = isProdEnvironment(host);
    const isRunningOnStaging = isProdEnvironment(host);
    const runsInSandbox = isProdBuild && !isRunningOnStaging && !isRunningOnProd;

    const shouldRestrictLog = NODE_ENV === 'production';
    const shouldUseConsoleLogger = false; //NODE_ENV !== 'test' && !shouldRestrictLog;

    const featureToggles = determineFeatureToggles(window);

    const env = {
        featureToggles,
        isLocalEnv,
        isProdBuild,
        isRunningOnProd,
        isRunningOnStaging,
        isTestEnv,
        runsInSandbox,
        runtimeConfig: {},
        shouldRestrictLog,
        shouldUseConsoleLogger,
        shouldSendMetrics,
    };

    env.runtimeConfig = getRuntimeConfig(window, env);

    return env;
};

export const env = configureEnv(window, process);

if (env.featureToggles.tracing) {
    // eslint-disable-next-line no-console
    console.log(env);
}
