import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import includes from 'lodash/fp/includes';
import noop from 'lodash/fp/noop';

import classNames from 'classnames';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Checkbox } from 'rio-uikit';

import TableHeader from './TableHeader';
import SelectionTableHeader from './SelectionTableHeader';

import '../../style/css/tables.less';

// table-layout-fixed table-bordered
const tableClassName = 'table table-hover table-column-overflow-hidden table-head-filled table-sticky';

/**
 * Table component for lists
 * TODO Make this a function
 */
export class ListTable extends PureComponent {
    render() {
        const { items } = this.props;
        if (!items) {
            return <FormattedMessage id='intl-msg:notFound' />;
        }
        const { className, columnDescriptors } = this.props;
        const tableHead = this.renderTableHead(columnDescriptors);
        const tableBody = this.renderTableBody(items);
        return (
            <table className={classNames(tableClassName, className)}>
                {tableHead}
                {tableBody}
            </table>
        );
    }

    renderTableHead(columnDescriptors) {
        const { allowSelection, sortBy, sortDir } = this.props;
        const selectionTableHeader = <SelectionTableHeader allowSelection={allowSelection}/>;
        const tableHeaders = map(columnDescriptor => {
            if (columnDescriptor.hidden) {
                return;
            }
            return <TableHeader columnDescriptor={columnDescriptor} sortBy={sortBy} sortDir={sortDir}/>;
        }, columnDescriptors);
        return (
            <thead className='table-head'>
                <tr>
                    {selectionTableHeader}
                    {tableHeaders}
                </tr>
            </thead>
        );
    }

    renderTableBody(items) {
        const tableRows = map(this.renderTableRow, items);
        return (
            <tbody>
                {
                    tableRows.length > 0 ? tableRows :
                        <tr>
                            <td colSpan={99} style={{textAlign: 'center'}}>
                                <FormattedMessage id='intl-msg:noDataText' />
                            </td>
                        </tr>
                }
            </tbody>
        );
    }

    renderTableRow = (item) => {
        const { itemKey, columnDescriptors, onRowClick, rowClassname } = this.props;
        const selectionTableData = this.renderSelectionTableData(item);
        const tableData = map(columnDescriptor => this.renderTableData(item, columnDescriptor), columnDescriptors);
        const key = item[itemKey];
        return (
            <tr className={classNames(rowClassname(item))} key={key} data-key={key} onClick={() => onRowClick(item)}>
                {selectionTableData}
                {tableData}
            </tr>
        );
    }

    renderSelectionTableData(item) {
        const { selectedAll, selectedItems, allowSelection, itemKey } = this.props;
        const selected = selectedAll || includes(item[itemKey], selectedItems);
        if (allowSelection) {
            return (
                <td className='selection'>
                    <Checkbox checked={selected}/>
                </td>
            );
        }
    }

    renderTableData = (item, columnDescriptor) => {
        if (columnDescriptor.hidden) {
            return;
        }
        if(typeof item[columnDescriptor.field] == 'boolean'){
            item[columnDescriptor.field] = String(item[columnDescriptor.field])
        }
        return (
            <td className={classNames(columnDescriptor.className)}>
                {
                    columnDescriptor.format ?
                        columnDescriptor.format(get(columnDescriptor.field, item), item) :
                        this.lookupObjectProperty(item, columnDescriptor.field)
                }
            </td>
        );
    }

    lookupObjectProperty = (item, prop) => {
        const theKeys = Object.getOwnPropertyNames(item);
        let lookup = {};
        theKeys.forEach(function(key) {
            lookup[key.toLowerCase()] = key;
        });
        return item[lookup[prop.toLowerCase()]];
    }
}

export default ListTable;

ListTable.defaultProps = {
    // props
    columnDescriptors: [],
    itemKey: 'id',
    allowSelection: false,
    selectedItems: [],
    selectedAll: false,
    // functions
    onRowClick: noop,
    onSelectItem: noop,
    onSelectAll: noop,
    onShowItem: noop,
    rowClassname: noop,
};

ListTable.propTypes = {
    // props
    className: PropTypes.string,
    items: PropTypes.array,
    itemKey: PropTypes.string,
    columnDescriptors: PropTypes.array,
    allowSelection: PropTypes.bool,
    selectedItems: PropTypes.array,
    selectedAll: PropTypes.bool,
    // functions
    onRowClick: PropTypes.func,
    onSelectItem: PropTypes.func,
    onSelectAll: PropTypes.func,
    onShowItem: PropTypes.func,
    rowClassname: PropTypes.func,
};
