// This is the runtime configuration being used for local development
// where authorization is usually mocked

export const localConfig = {
    id: 'env.local',
    backend: {
        DEVICE_MONITORING_SERVICE: 'http://localhost:8080/api/deviceinitmonitor',
        SYSTEM_STATE_NOTIFICATIONS_SERVICE: 'http://localhost:8080/api/tbm3systemstatenotification/v1',
        DEVICE_SERVICE: 'http://localhost:8080/api/deviceService',
        MESSAGESENDER_SERVICE: 'http://localhost:8080/api/tbm3systemstatenotification/v1',
    },
    homeRoute: `${window.location.origin}`,
    appMenuUri: 'http://localhost:8040/#app-menu',
    userMenuUri: 'http://localhost:8040/#user-menu',
    login: {
        authority: `${window.location.origin}/oauth`,
        mockAuthorization: true,
        mockLocale: 'de-DE',
        preventRedirect: true,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
    logoutUri: `${window.location.origin}`,
    features: {
        nasysAvailable: true,
        vcmAvailable: true,
        certificatesAvailable: true,
    },
};

