export const DELETE_DATABASE = 'DELETE_DATABASE';
export const SUCCESSFUL_DELETE_DATABASE = 'SUCCESSFUL_DELETE_DATABASE';
export const FAILED_DELETE_DATABASE = 'FAILED_DELETE_DATABASE';

export const CHECK_DEVICES_AND_ADD_RESULTS_TO_DB = 'CHECK_DEVICES_AND_ADD_RESULTS_TO_DB';
export const SUCCESSFUL_CHECK_DEVICES_AND_ADD_RESULTS_TO_DB = 'SUCCESSFUL_CHECK_DEVICES_AND_ADD_RESULTS_TO_DB';
export const FAILED_CHECK_DEVICES_AND_ADD_RESULTS_TO_DB = 'FAILED_CHECK_DEVICES_AND_ADD_RESULTS_TO_DB';

export const CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB = 'CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB';
export const SUCCESSFUL_CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB = 'SUCCESSFUL_CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB';
export const FAILED_CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB = 'FAILED_CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB';

export const SET_MESSAGE = 'SET_MESSAGE';

export const deleteDatabase = () => {
    return {
        type: DELETE_DATABASE,
    };
};

export const successfulDeleteDatabase = () => {
    return {
        type: SUCCESSFUL_DELETE_DATABASE,
    };
};

export const failedDeleteDatabase = () => {
    return {
        type: FAILED_DELETE_DATABASE,
    };
};

export const checkDevicesAndAddResultsToDb = (newTBM3s) => {
    return {
        type: CHECK_DEVICES_AND_ADD_RESULTS_TO_DB,
        newTBM3s,
    };
};

export const successfulCheckDevicesAndAddResultsToDb = (response) => {
    return {
        type: SUCCESSFUL_CHECK_DEVICES_AND_ADD_RESULTS_TO_DB,
        response,
    };
};

export const failedCheckDevicesAndAddResultsToDb = () => {
    return {
        type: FAILED_CHECK_DEVICES_AND_ADD_RESULTS_TO_DB,
    };
};

export const uploadSREDataToDb = (newTBM3s, filename) => {
    return {
        type: CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB,
        newTBM3s,
        filename,
    };
};

export const successUploadSREDataToDb = (response) => {
    return {
        type: SUCCESSFUL_CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB,
        response,
    };
};

export const failedUploadSREDataToDb = (message) => {
    return {
        type: FAILED_CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB,
        message,
    };
};

export const setMessage = (message, messageType) => {
    return {
        type: SET_MESSAGE,
        message,
        messageType,
    };
};
