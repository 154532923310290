import {call, fork, put, select, takeLatest} from "redux-saga/effects";
import {getDeviceMonitoringBackend} from "../selectors/backendSelectors";
import {doHandleErrorSaga, getHTTP} from "./sagaUtil";
import {showErrorMessage} from "../actions/ui/notificationsActions";
import {
    FETCH_CERTIFICATES_RECORDS,
    successfulFetchCertificateRecords
} from "../actions/serviceCertificateActions";

export function* getServiceURL() {
    const serviceURL = yield select(getDeviceMonitoringBackend);
    return `${serviceURL}`;
}

export function* doFetchServiceCertificateRecords() {
    try {
        const url = yield call(getServiceURL);
        const response = yield call(getHTTP, `${url}/service-certificates`);
        yield put(successfulFetchCertificateRecords({
            ...response,
        }));
    } catch (error) {
        yield fork(doHandleErrorSaga, error);
        yield put(showErrorMessage(error));
    }
}

export function* fetchServiceCertificateRecordsSaga() {
    yield takeLatest(FETCH_CERTIFICATES_RECORDS,doFetchServiceCertificateRecords);
}
