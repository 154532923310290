import {
    CHECK_DEVICES_AND_ADD_RESULTS_TO_DB,
    SUCCESSFUL_CHECK_DEVICES_AND_ADD_RESULTS_TO_DB,
    FAILED_CHECK_DEVICES_AND_ADD_RESULTS_TO_DB,
    CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB,
    SUCCESSFUL_CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB,
    FAILED_CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB,
    SET_MESSAGE,
} from './../actions/uploadActions';

const DEFAULT_STATE = {
    loading: false,
};

const setLoading = (state, loading) => {
    return {
        ...state,
        loading,
    };
};

const successfulFetchAllDevices = (state, action) => {
    const message = action.response.message || 'done';
    return {
        ...state,
        message,
        messageType: 'bs-callout-success',
        loading: false,
    };
};

const failedUploadSREData = (state, action) => {
    return {
        ...state,
        message: action.message,
        messageType: 'bs-callout-danger',
        loading: false,
    };
};

const setMessage = (state, action) => {
    return {
        ...state,
        message: action.message,
        messageType: action.messageType,
    };
};

export function uploadReducer(state = DEFAULT_STATE, action = {}) {
    switch (action.type) {
        case CHECK_DEVICES_AND_ADD_RESULTS_TO_DB:
            return setLoading(state, true);
        case FAILED_CHECK_DEVICES_AND_ADD_RESULTS_TO_DB:
            return setLoading(state, false);
        case SUCCESSFUL_CHECK_DEVICES_AND_ADD_RESULTS_TO_DB:
            return successfulFetchAllDevices(state, action);
        case CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB:
            return setLoading(state, true);
        case FAILED_CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB:
            return failedUploadSREData(state, action);
        case SUCCESSFUL_CHECK_SRE_DEVICES_AND_ADD_RESULTS_TO_DB:
            return successfulFetchAllDevices(state, action);
        case SET_MESSAGE:
            return setMessage(state, action);
        default:
            return state;
    }
}

export default uploadReducer;
