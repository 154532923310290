import reduce from 'lodash/reduce';

const hwSerialRegEx = /^(\d){6}-(\d){4}$/;
const shortPartialHwSerialRegEx = /^(\d){3,6}$/;
const longPartialHwSerialRegEx = /^(\d){6}-(\d){1,4}$/;

/**
 * Validates whether the given hwSerial is of format YYMMDD-XXXX
 * @param {string} hwSerial The hwSerial to be validated
 * @returns {bool} The error code
 */
export function validateHwSerial(hwSerial) {
    if (hwSerial && typeof hwSerial === 'string' &&
        hwSerialRegEx.test(hwSerial)) {
        return true;
    }
    return false;
}

/**
 * Validates whether the given list of hwSeriasl is of format YYMMDD-XXXX
 * @param {array} hwSerials The hwSerial to be validated
 * @returns {bool} The error code
 */
export function validateHwSerials(hwSerials) {
    const allValid = reduce(hwSerials, (valid, hwSerial) => {
        return valid && validateHwSerial(hwSerial);
    }, true);
    return allValid;
}

/**
 * Validates whether the given partial hwSerial is of format YYMMDD-XXXX
 * @param {string} hwSerial The hwSerial to be validated
 * @returns {bool} The error code
 */
export function validatePartialHwSerial(hwSerial) {
    if (hwSerial && typeof hwSerial === 'string' &&
        (shortPartialHwSerialRegEx.test(hwSerial) || longPartialHwSerialRegEx.test(hwSerial))) {
        return true;
    }
    return false;
}
