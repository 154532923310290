import noop from 'lodash/noop';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';

import { validateHwSerial } from '../utils/hwSerialValidator';

/**
 * Device finder component
 */
export class DeviceFinder extends Component {

    constructor() {
        super();
        this.state = {
            hwSerial: '',
            isValidHwSerial: true,
        };
    }

    onInputChange(e) {
        const hwSerial = e.target.value;
        const isValidHwSerial = hwSerial === '' || validateHwSerial(hwSerial);
        this.setState({
            hwSerial,
            isValidHwSerial,
        });
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.onShowControlDevice({
            serialNumber: this.state.hwSerial,
        });
    }

    render() {
        const { intl } = this.props;
        return (
            <div className='device-finder container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <form className='form-horizontal' onSubmit={(e) => this.onSubmit(e)}>
                            <div className={`form-group ${this.state.isValidHwSerial ? '' : 'has-error'}`}>
                                <span className='input-group'>
                                    <span className='input-group-addon'>
                                        <span className='rioglyph rioglyph-search' aria-hidden='true'></span>
                                    </span>
                                    <input className='form-control'
                                        value={this.state.hwSerial}
                                        placeholder={intl.formatMessage({ id: 'intl-msg:serialNumberPrompt' })}
                                        type='text'
                                        onChange={(event) => this.onInputChange(event)}/>
                                    <span className='input-group-btn' onClick={(e) => this.onSubmit(e)}>
                                        <button className={`btn btn-primary ${this.state.isValidHwSerial && this.state.hwSerial !== '' ? '' : 'disabled'}`}>
                                            <span><FormattedMessage id='intl-msg:search' /></span>
                                        </button>
                                    </span>
                                </span>
                                <span className='help-block'>
                                    {
                                        this.state.isValidHwSerial ?
                                            undefined :
                                            intl.formatMessage({ id: 'intl-msg:invalidSerialNumber' })
                                    }
                                </span>
                            </div>
                        </form>
                    </div>
                 </div>
            </div>
        );
    }
}

export default injectIntl(DeviceFinder);

DeviceFinder.defaultProps = {
    intl: {
        formatMessage: noop,
    },
    onShowControlDevice: noop,
};

DeviceFinder.propTypes = {
    // props
    intl: intlShape.isRequired,
    // functions
    onShowControlDevice: PropTypes.func.isRequired,
};
