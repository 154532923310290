import { combineReducers } from 'redux';
import uploadReducer from './uploadReducer';
import showResultReducer from './showResultReducer';
import singleDeviceReducer from './singleDeviceReducer';
import systemStateReducer from './systemStateReducer';
import devicesSigningReducer from './devicesSigningReducer';
import nasysJournalReducer from './journal/nasysJournalReducer';
import stoneridgeJournalReducer from './journal/stoneridgeJournalReducer';
import certificateReducer from "./certificateReducer";

export const combinedReducers = combineReducers({
    upload: uploadReducer,
    showResult: showResultReducer,
    singleDevice: singleDeviceReducer,
    systemState: systemStateReducer,
    devicesSigning: devicesSigningReducer,
    nasysJournal: nasysJournalReducer,
    stoneridgeJournal: stoneridgeJournalReducer,
    serviceCertificate: certificateReducer,
});

export const deviceMonitorReducers = {
    deviceMonitor: combinedReducers,
};

export default deviceMonitorReducers;
