import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SpinnerInfoBox } from 'rio-uikit';
import LoadMoreComponent from '../LoadMoreComponent';
import {
    getJournalRecords,
    getCurrentPageOfJournal,
    getLoadingState,
    getMaxPagesOfJournal,
} from '../../selectors/journal/stoneridgeJournalSelectors';
import { fetchStoneridgeJournalRecords } from '../../actions/journal/stoneridgeJournalActions';
import ListTable from '../table/ListTable';
import { STONERIDGE_JOURNAL_TABLE_COLUMN_DESCRIPTORS } from '../table/ColumnDescriptors';

class StoneridgeJournal extends Component {

    constructor(props) {
        super(props);
        this.getStoneridgeJournalData = this.getStoneridgeJournalData.bind(this);
        this.loadMoreData = this.loadMoreData.bind(this);
        this.translate = this.translate.bind(this);
    }

    render() {
        return (
            <div>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <h2><FormattedMessage id='intl-msg:journal:results'/></h2>
                    </div>
                </div>
                {
                    this.props.loading &&
                    <div>
                        <SpinnerInfoBox text={this.translate('intl-msg:journal:loading')} isInverse={false}/>
                    </div>
                }
                <div className='overflow-auto display-flex'>
                    <div className='react-bs-table-container'>
                        <div className='react-bs-table react-bs-table-bordered rio-bs-table'>
                            <div className='react-bs-container-header table-header-wrapper'>
                                <ListTable items={this.props.journalRecords}
                                    className={'table-bordered'}
                                    columnDescriptors={STONERIDGE_JOURNAL_TABLE_COLUMN_DESCRIPTORS}/>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadMoreComponent isLastPage={this.props.currentPage === this.props.maxPages - 1}
                    loadMoreData={this.loadMoreData}/>
            </div>
        );
    }

    componentDidMount() {
        this.getStoneridgeJournalData(0);
    }

    getStoneridgeJournalData(pageToShow) {
        this.props.fetchStoneridgeJournal(pageToShow);
    }

    loadMoreData() {
        const currentPage = this.props.currentPage;
        if (currentPage < this.props.maxPages) {
            this.getStoneridgeJournalData(currentPage + 1);
        }
    }

    translate(id) {
        return this.props.intl.formatMessage({ id: id });
    }
}

export const mapStateToProps = (state) => ({
    loading: getLoadingState(state),
    journalRecords: getJournalRecords(state),
    currentPage: getCurrentPageOfJournal(state),
    maxPages: getMaxPagesOfJournal(state),
});

export const mapDispatchToProps = (dispatch) => ({
    fetchStoneridgeJournal: (pageToShow) => {
        dispatch(fetchStoneridgeJournalRecords(pageToShow));
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(StoneridgeJournal)));
