import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { SpinnerInfoBox } from 'rio-uikit';
import {
    getCurrentPageOfJournal,
    getJournalRecords,
    getLoadingState,
    getMaxPagesOfJournal,
} from '../../selectors/journal/nasysJournalSelectors';
import { withRouter } from 'react-router-dom';
import { fetchJournalRecords } from '../../actions/journal/nasysJournalActions';
import LoadMoreComponent from '../LoadMoreComponent';
import ListTable from '../table/ListTable';
import { NASYS_JOURNAL_TABLE_COLUMN_DESCRIPTORS } from '../table/ColumnDescriptors';

// TODO - add german translations to src/features/lang/translations/de-DE.json
class NasysJournal extends Component {

    constructor(props) {
        super(props);
        this.getNasysJournalData = this.getNasysJournalData.bind(this);
        this.loadMoreData = this.loadMoreData.bind(this);
        this.translate = this.translate.bind(this);
    }

    render() {
        return (
            <div>
                <div className='row'>
                    <div className='col-md-12'>
                        <h2><FormattedMessage id='intl-msg:journal:results'/></h2>
                    </div>
                </div>
                {
                    this.props.loading &&
                        <div>
                            <SpinnerInfoBox text={this.translate('intl-msg:journal:loading')} isInverse={false}/>
                        </div>
                }
                <ListTable items={this.props.journalRecords}
                    className={'table, table-bordered'}
                    columnDescriptors={NASYS_JOURNAL_TABLE_COLUMN_DESCRIPTORS}/>
                <LoadMoreComponent isLastPage={this.props.currentPage === this.props.maxPages - 1}
                    loadMoreData={this.loadMoreData}/>
            </div>
        );
    }

    componentDidMount() {
        this.getNasysJournalData(0);
    }

    getNasysJournalData(pageToShow) {
        this.props.fetchNasysJournal(pageToShow);
    }

    loadMoreData() {
        const currentPage = this.props.currentPage;
        if (currentPage < this.props.maxPages) {
            this.getNasysJournalData(currentPage + 1);
        }
    }

    translate(id) {
        return this.props.intl.formatMessage({ id: id });
    }
}

export const mapStateToProps = (state) => ({
    loading: getLoadingState(state),
    journalRecords: getJournalRecords(state),
    currentPage: getCurrentPageOfJournal(state),
    maxPages: getMaxPagesOfJournal(state),
});

export const mapDispatchToProps = (dispatch) => ({
    fetchNasysJournal: (pageToShow) => {
        dispatch(fetchJournalRecords(pageToShow));
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(NasysJournal)));
