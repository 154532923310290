export const SHOW_RESULTS_TABLE_COLUMN_DESCRIPTORS = [
    {
        id: 'vin7',
        field: 'vin7',
        label: 'intl-msg:vin7',
    }, {
        id: 'vin17',
        field: 'vin17',
        label: 'intl-msg:vin',
    }, {
        id: 'imei',
        field: 'imei',
        label: 'IMEI',
    }, {
        id: 'iccid',
        field: 'iccid',
        label: 'ICCID',
    }, {
        id: 'serialNumber',
        field: 'serialNumber',
        label: 'SERIALNUMBER',
    }, {
        id: 'created',
        field: 'created',
        label: 'NASYS CREATED',
    }, {
        id: 'timestamp',
        field: 'timestamp',
        label: 'CERTIFICATION TIME',
    }, {
        id: 'initSuccessfulText',
        field: 'initSuccessfulText',
        label: 'CERTIFICATION RESULT',
    }, {
        id: 'initState',
        field: 'initState',
        label: 'INIT STATE',
    }, {
        id: 'errorState',
        field: 'errorState',
        label: 'INIT ERROR',
    }, {
        id: 'vehicleInitText',
        field: 'vehicleInitText',
        label: 'VEHICLE INIT',
    }, {
        id: 'replacedText',
        field: 'replacedText',
        label: 'MOST LIKELY REPLACED',
    },
];

export const DEVICES_SIGNING_TABLE_COLUMN_DESCRIPTORS = [
    {
        id: 'serialNumber',
        field: 'serialNumber',
        label: 'intl-msg:serialNumberHeader',
    }, {
        id: 'lastrequest',
        field: 'lastrequest',
        label: 'intl-msg:lastSigningRequestTime',
    }, {
        id: 'imei',
        field: 'imei',
        label: 'IMEI',
    }, {
        id: 'imsi',
        field: 'imsi',
        label: 'IMSI',
    }, {
        id: 'iccid',
        field: 'iccid',
        label: 'ICCID',
    }, {
        id: 'requestCnt',
        field: 'requestCnt',
        label: 'intl-msg:noRequests',
    }, {
        id: 'errorStatus',
        field: 'errorStatus',
        label: 'intl-msg:errorStatus',
    }, {
        id: 'signingStatus',
        field: 'signingStatus',
        label: 'intl-msg:signingStatus',
    },
];

export const NASYS_UPLOAD_TABLE_COLUMN_DESCRIPTORS = [
    {
        id: 'vin7',
        field: 'vin7',
        label: 'intl-msg:vin7',
    }, {
        id: 'vin17',
        field: 'vin17',
        label: 'intl-msg:vin',
    }, {
        id: 'created',
        field: 'created',
        label: 'created',
    }, {
        id: 'imei',
        field: 'imei',
        label: 'IMEI',
    }, {
        id: 'iccid',
        field: 'iccid',
        label: 'ICCID',
    },
];

export const STONERIDGE_UPLOAD_TABLE_COLUMN_DESCRIPTORS = [
    {
        id: 'ID',
        field: 'ID',
        label: 'ID',
    }, {
        id: 'TBM3_serial_number',
        field: 'TBM3_serial_number',
        label: 'TBM3_serial_number',
    }, {
        id: 'VCM_serial_number',
        field: 'VCM_serial_number',
        label: 'VCM_serial_number',
    }, {
        id: 'zsb_date_assembly',
        field: 'zsb_date_assembly',
        label: 'zsb_date_assembly',
    }, {
        id: 'SIM_ID',
        field: 'SIM_ID',
        label: 'SIM_ID',
    }, {
        id: 'TBM3_IMEI',
        field: 'TBM3_IMEI',
        label: 'TBM3_IMEI',
    }, {
        id: 'IMSI',
        field: 'IMSI',
        label: 'IMSI',
    }, {
        id: 'ICCID',
        field: 'ICCID',
        label: 'ICCID',
    },
];

export const DEVICE_CERTIFICATES_TABLE_COLUMN_DESCRIPTORS = [
    {
        id: 'deviceType',
        field: 'deviceType',
        label: 'intl-msg:deviceType',
    }, {
        id: 'expire',
        field: 'expire',
        label: 'intl-msg:expire',
    }, {
        id: 'validFrom',
        field: 'validFrom',
        label: 'intl-msg:validFrom',
    }, {
        id: 'certusage',
        field: 'certusage',
        label: 'intl-msg:certusage',
    }, {
        id: 'valid',
        field: 'valid',
        label: 'intl-msg:valid',
    },
];

export const NASYS_JOURNAL_TABLE_COLUMN_DESCRIPTORS = [
    {
        id: 'invalidDevices',
        field: 'invalidDevices',
        label: 'intl-msg:journal:invalid',
        className: 'min-width-150',
    }, {
        id: 'duplicatedDevices',
        field: 'duplicatedDevices',
        label: 'intl-msg:journal:duplicated',
        className: 'min-width-150',
    }, {
        id: 'savedDevices',
        field: 'savedDevices',
        label: 'intl-msg:journal:saved',
        className: 'min-width-150',
    }, {
        id: 'status',
        field: 'status',
        label: 'intl-msg:journal:status',
        className: 'min-width-150',
    }, {
        id: 'importDate',
        field: 'importDate',
        label: 'intl-msg:journal:date',
        className: 'min-width-150',
    },
];

export const STONERIDGE_JOURNAL_TABLE_COLUMN_DESCRIPTORS = [
    {
        id: 'filename',
        field: 'filename',
        label: 'intl-msg:journal:filename',
        className: 'width-150 react-bs-container-header table-header-wrapper text-overflow-unset',
    }, {
        id: 'savedDevices',
        field: 'savedDevices',
        label: 'intl-msg:journal:saved',
        className: 'width-80 react-bs-container-header table-header-wrapper text-overflow-unset',
    }, {
        id: 'status',
        field: 'status',
        label: 'intl-msg:journal:status',
        className: 'width-50 react-bs-container-header table-header-wrapper text-overflow-unset',
    }, {
        id: 'importDate',
        field: 'importDate',
        label: 'intl-msg:journal:date',
        className: 'width-50 react-bs-container-header table-header-wrapper text-overflow-unset',
    }, {
        id: 'isUpdate',
        field: 'isUpdate',
        label: 'intl-msg:journal:update',
        className: 'width-50 react-bs-container-header table-header-wrapper text-overflow-unset',
    }, {
        id: 'message',
        field: 'message',
        label: 'intl-msg:journal:message',
        className: 'width-250 react-bs-container-header table-header-wrapper text-overflow-unset',
    },
];

export const SERVICE_CERTIFICATES_TABLE_COLUMN_DESCRIPTORS = [
    {
        id: 'host',
        field: 'host',
        label: 'intl-msg:certificates:host',
    }, {
        id: 'service',
        field: 'service',
        label: 'intl-msg:certificates:service',
    }, {
        id: 'expirationDate',
        field: 'expirationDate',
        label: 'intl-msg:certificates:expirationDate',
    }, {
        id: 'remainingDays',
        field: 'remainingDays',
        label: 'intl-msg:certificates:remaining_days',
    }, {
        id: 'createdBy',
        field: 'createdBy',
        label: 'intl-msg:certificates:created_by',
    },
];
