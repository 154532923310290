import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Pager } from 'rio-uikit';
import { FormattedMessage } from 'react-intl';

export default class LoadMoreComponent extends PureComponent {

    render() {
        const { isLastPage, loadMoreData } = this.props;

        return (
            <div className='row'>
                <div className='col-md-12'>
                    <Pager>
                        <Pager.Item className='paginationItem'
                            disabled={isLastPage}
                            href='#'
                            onClick={loadMoreData}>
                            <FormattedMessage id='intl-msg:paging.loadMore'/>
                            <span className='rioglyph rioglyph-more'/>
                        </Pager.Item>
                    </Pager>
                </div>
            </div>
        );
    }
}

LoadMoreComponent.propTypes = {
    isLastPage: PropTypes.bool,
    loadMoreData: PropTypes.func,
};
