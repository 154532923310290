import React from 'react';
import { FormattedTime } from 'react-intl';

const yesOrNo = field => {
    const classes = field ? 'rioglyph-ok text-success' : 'rioglyph-remove text-danger';
    return (
        <span className={`rioglyph text-size-large align-middle ${classes}`} />
    );
};
const isLatam = value => {
    const classes = value && value.startsWith('8955') ? 'rioglyph-ok text-success' : 'rioglyph-remove text-danger';
    return (
        <span className={`rioglyph text-size-large align-middle ${classes}`} />
    );
};

const toDate = value => {
    return value ? <FormattedTime value={value} year='numeric' month='2-digit' day='2-digit' /> : '';
};

export const deviceRowDescriptors = [{
    header: <div><strong>{'TBM3'}</strong></div>,
}, {
    field: 'serialNumber',
    format: (field) => <div><strong>{field}</strong></div>,
}, {
    field: 'iccid',
}, {
    field: 'imsi',
}, {
    field: 'imei',
}, {
    field: 'vin7',
}, {
    field: 'hwVariant',
}, {
    field: 'base_sw_version',
    value: (controlDevice) => controlDevice['baseSwVersion'],
}, {
    field: 'origVin',
    value: (controlDevice) => controlDevice['vin17'],
}, {
    field: 'currentVin',
    value: (controlDevice) => controlDevice['vin'],
}, {
    field: 'assetid',
    value: (controlDevice) => controlDevice['equipmentId'],
}, {
    field: 'nasysCreated',
    value: (controlDevice) => controlDevice['created'],
    format: toDate,
}, {
    field: 'initSuccessful',
    format: yesOrNo,
}, {
    field: 'registrationTimestamp',
    value: (controlDevice) => controlDevice['timestamp'],
    format: toDate,
}, {
    field: 'signingStatus',
    value: (controlDevice) => controlDevice['signingStatus'],
}, {
    field: 'errorMessage',
    value: (controlDevice) => controlDevice['errorMessage'],
}, {
    field: 'lastActivity',
    format: toDate,
}, {
    field: 'vehicleInit',
    format: yesOrNo,
}, {
    field: 'replaced',
    format: yesOrNo,
}, {
    field: 'latamDevice',
    value: (controlDevice) => controlDevice['iccid'],
    format: isLatam,
}];

export const deviceVcmRowDescriptors = [{
    header: <div><strong>{'VCM'}</strong></div>,
}, {
    field: 'serialNumber',
    format: (field) => <div><strong>{field}</strong></div>,
}, {
    field: 'initSuccessful',
    format: yesOrNo,
}, {
    field: 'registrationTimestamp',
    value: (controlDevice) => controlDevice['timestamp'],
    format: toDate,
}, {
    field: 'signingStatus',
    value: (controlDevice) => controlDevice['signingStatus'],
}, {
    field: 'errorMessage',
    value: (controlDevice) => controlDevice['errorMessage'],
}];

