import {
    AUTHENTICATION_SERVICE,
    DEVICE_MONITORING_SERVICE,
    SYSTEM_STATE_NOTIFICATIONS_SERVICE,
    DEVICE_SERVICE,
    MESSAGESENDER_SERVICE,
} from '../constants/services';

const backendSelector = state => state.config.backend;

export const getAuthenticationBackend = state => backendSelector(state)[AUTHENTICATION_SERVICE];
export const getDeviceMonitoringBackend = state => backendSelector(state)[DEVICE_MONITORING_SERVICE];
export const getDeviceSystemStateBackend = state => backendSelector(state)[SYSTEM_STATE_NOTIFICATIONS_SERVICE];
export const getDeviceBackend = state => backendSelector(state)[DEVICE_SERVICE];
export const getMessageSenderBackend = state => backendSelector(state)[MESSAGESENDER_SERVICE];
